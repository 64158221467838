import React, { useEffect, useState } from "react";
import { Anchor, Card, Tooltip } from "antd";
import { CopyOutlined, AppstoreOutlined } from "@ant-design/icons";

const { Link } = Anchor;

const ApiDetailView = ({ apis, selectedApiId }) => {
  const [tooltipText, setTooltipText] = useState("Copy to clipboard");
  const [isLargeScreen, setIsLargeScreen] = useState(window.innerWidth >= 1024);

  const handleCopy = (text) => {
    navigator.clipboard.writeText(text).then(() => {
      setTooltipText("Route copied!");
      setTimeout(() => setTooltipText("Copy to clipboard"), 2000);
    });
  };

  const handleObjectCopy = (obj) => {
    const objStr = JSON.stringify(obj, null, 2);
    navigator.clipboard.writeText(objStr).then(() => {
      setTooltipText("Object copied!");
      setTimeout(() => setTooltipText("Copy to clipboard"), 2000);
    });
  };

  useEffect(() => {
    if (selectedApiId) {
      const element = document.getElementById(selectedApiId);
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    }

    const handleResize = () => {
      setIsLargeScreen(window.innerWidth >= 1024);
    };
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [selectedApiId]);

  const getMethodColor = (method) => {
    switch (method) {
      case "GET":
        return "#3B82F6";
      case "POST":
        return "#22C55E";
      case "PUT":
        return "#EAB308";
      default:
        return "#EF4444";
    }
  };

  return (
    <section
      style={{
        width: "100%",
        paddingTop: isLargeScreen ? "0" : "8px",
      }}
    >
      <Anchor affix={false}>
        {apis.map((api) => (
          <div
            id={`api-${api.id}`}
            key={api.id}
            style={{ marginBottom: "32px" }}
          >
            <Card bordered={false}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  borderBottom: "1px solid #d1d5db",
                  paddingBottom: "16px",
                  marginBottom: "8px",
                }}
              >
                <h3 style={{ fontWeight: 600, fontSize: "20px" }}>
                  {api.apiName}
                </h3>
                <div
                  id="grouping"
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: "8px",
                  }}
                >
                  <AppstoreOutlined /> {api.group}
                </div>
              </div>
              <div>
                <strong>Method:</strong>
                <span
                  style={{
                    marginLeft: "4px",
                    fontWeight: 600,
                    color: getMethodColor(api.method),
                  }}
                >
                  {api.method}
                </span>
              </div>
              <br />
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  flexWrap: isLargeScreen ? "nowrap" : "wrap",
                }}
              >
                <p>
                  <strong>Route:</strong>
                </p>
                <Tooltip title={tooltipText}>
                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                      gap: "4px",
                      cursor: "pointer",
                      marginTop: isLargeScreen ? "0" : "8px",
                    }}
                    onClick={() => handleCopy(api.route)}
                  >
                    <span
                      style={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "space-between",
                        backgroundColor: "#F2F9B7",
                        fontWeight: "normal",
                        borderRadius: "6px",
                        paddingLeft: "8px",
                        fontSize: "18px",
                        fontFamily: "monospace",
                      }}
                    >
                      {api.route}
                      <CopyOutlined
                        onClick={() => handleCopy(api.route)}
                        style={{ color: "#64748b" }}
                      />
                    </span>
                  </div>
                </Tooltip>
              </div>
              <br />
              <p>
                <strong>Description:</strong> {api.description}
              </p>
              <br />
              <div>
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <strong>Request:</strong>
                  <Tooltip title={tooltipText}>
                    <CopyOutlined
                      onClick={() => handleObjectCopy(api.request)}
                      style={{ color: "#64748b" }}
                    />
                  </Tooltip>
                </div>
                <pre
                  style={{
                    backgroundColor: "#f3f4f6",
                    padding: "8px",
                    borderRadius: "6px",
                    overflow: "auto",
                  }}
                >
                  <code>{JSON.stringify(api.request, null, 2)}</code>
                </pre>
              </div>
              <br />
              <div>
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <strong>Response:</strong>
                  <Tooltip title={tooltipText}>
                    <CopyOutlined
                      onClick={() => handleObjectCopy(api.response)}
                      style={{ color: "#64748b" }}
                    />
                  </Tooltip>
                </div>
                <pre
                  style={{
                    backgroundColor: "#f3f4f6",
                    padding: "8px",
                    borderRadius: "6px",
                    overflow: "auto",
                  }}
                >
                  <code>{JSON.stringify(api.response, null, 2)}</code>
                </pre>
              </div>
            </Card>
          </div>
        ))}
      </Anchor>
    </section>
  );
};

export default ApiDetailView;
