// ApiGridView.jsx
import { useState, useEffect } from "react";
import { CopyOutlined, ExportOutlined } from "@ant-design/icons";
import { Card, Badge, Tooltip, Button } from "antd";

const ApiCard = ({
  id,
  apiName,
  method,
  route,
  description,
  tooltip,
  onCopy,
  onApiClick,
}) => {
  const getBadgeColor = (method) => {
    switch (method) {
      case "GET":
        return "blue";
      case "POST":
        return "green";
      case "PUT":
        return "yellow";
      default:
        return "red";
    }
  };

  return (
    <Badge.Ribbon text={method} color={getBadgeColor(method)}>
      <Card
        title={<span style={{ fontSize: "1rem" }}>{apiName}</span>}
        style={{ width: "100%", height: "200px", cursor: "pointer" }}
      >
        <Tooltip title={tooltip}>
          <div
            style={{ display: "flex", gap: "0.25rem", cursor: "pointer" }}
            onClick={() => onCopy(route)}
          >
            <span
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "space-between",
                backgroundColor: "#F2F9B7",
                fontWeight: "normal",
                borderRadius: "0.375rem",
                paddingLeft: "0.5rem",
                fontSize: "0.875rem",
                fontFamily: "monospace",
                marginBottom: "0.75rem",
              }}
            >
              {route}
              <CopyOutlined
                onClick={() => onCopy(route)}
                style={{ color: "#64748b" }}
              />
            </span>
          </div>
        </Tooltip>
        <p style={{ fontSize: "0.875rem", color: "#6c757d" }}>{description}</p>
        <div
          style={{ width: "100%", display: "flex", justifyContent: "flex-end" }}
        >
          <Button
            icon={<ExportOutlined />}
            size="small"
            onClick={() => onApiClick(id)}
          />
        </div>
      </Card>
    </Badge.Ribbon>
  );
};

const ApiGridView = ({ apis, onApiClick }) => {
  const [tooltipText, setTooltipText] = useState("Copy to clipboard");
  const [isLargeScreen, setIsLargeScreen] = useState(window.innerWidth >= 1024);

  useEffect(() => {
    const handleResize = () => {
      setIsLargeScreen(window.innerWidth >= 1024);
    };
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const handleCopy = (text) => {
    navigator.clipboard.writeText(text).then(() => {
      setTooltipText("Route copied!");
      setTimeout(() => setTooltipText("Copy to clipboard"), 2000);
    });
  };

  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        gap: isLargeScreen ? "5rem" : "2rem",
      }}
    >
      <div
        style={{
          display: "grid",
          gridTemplateColumns: isLargeScreen
            ? "repeat(4, 1fr)"
            : "repeat(1, 1fr)",
          gap: "1.25rem",
          width: "100%",
          height: isLargeScreen ? "66.666%" : "80%",
        }}
      >
        {apis.map((el) => (
          <ApiCard
            key={el.id}
            id={el.id}
            apiName={el.apiName}
            method={el.method}
            route={el.route}
            description={el.description}
            tooltip={tooltipText}
            onCopy={handleCopy}
            onApiClick={onApiClick}
          />
        ))}
      </div>
    </div>
  );
};

export default ApiGridView;
