import React, { useState } from 'react';
import { Card, InputNumber, Dropdown, Menu, Typography, notification, Tabs } from 'antd';
import { FiPlay, FiLoader, FiChevronDown } from 'react-icons/fi';
import { BsGear } from 'react-icons/bs';
import { Handle, Position } from 'react-flow-renderer';

const { Text } = Typography;

const RerankerModelStep = () => {
  const [loading, setLoading] = useState(false);
  const [modelName, setModelName] = useState('OpenAI Embeddings');
  const [rerankingParams, setRerankingParams] = useState({
    topK: 4,
    similarityMetric: 'cosine',
    maxChunks: 100,
    thresholdScore: 0.5,
    contextWindowSize: 512,
  });

  // Menu for selecting Reranker Model
  const modelMenu = (
    <Menu onClick={(e) => setModelName(e.key)}>
      <Menu.Item className="dropdown-menu-item" key="OpenAI Embeddings">OpenAI Embeddings</Menu.Item>
      <Menu.Item className="dropdown-menu-item" key="BAAI/bge-reranker-large">BAAI/bge-reranker-large</Menu.Item>
      <Menu.Item className="dropdown-menu-item" key="BAAI/bge-reranker-base">BAAI/bge-reranker-base</Menu.Item>
      <Menu.Item className="dropdown-menu-item" key="BAAI/bge-en-icl">BAAI/bge-en-icl</Menu.Item>
    </Menu>
  );

  const handleRun = () => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
      notification.success({
        message: 'Reranker Configuration Applied',
        description: 'Your reranker configuration has been successfully applied.',
      });
    }, 2000);
  };

  return (
    <Card
      title={
        <div className="card-header">
          <div className="header-title">
            <BsGear className="icon" />
            <span className="header-text">Reranker Model Step</span>
          </div>
          <div onClick={handleRun} className="run-button">
            {loading ? (
              <FiLoader className="loader" spin />
            ) : (
              <FiPlay className="play" />
            )}
          </div>
        </div>
      }
      bordered={false}
      className="reranker-card"
    >
      <Tabs defaultActiveKey="1">
        <Tabs.TabPane tab="Model Name" key="1">
          <div className="section">
            <Text className="section-title">Select Model Name</Text>
            <Dropdown overlay={modelMenu} trigger={['click']}>
              <div className="dropdown-button">
                <span>{modelName}</span>
                <FiChevronDown className="dropdown-icon" />
              </div>
            </Dropdown>
          </div>
        </Tabs.TabPane>

        <Tabs.TabPane tab="Reranking Options" key="2">
          <div className="section">
            <Text className="section-title">Top K</Text>
            <InputNumber
              min={1}
              max={100}
              value={rerankingParams.topK}
              onChange={(value) => setRerankingParams({ ...rerankingParams, topK: value })}
              className="input-field"
            />
          </div>

          <div className="section">
            <Text className="section-title">Similarity Metric</Text>
            <Dropdown overlay={
              <Menu onClick={(e) => setRerankingParams({ ...rerankingParams, similarityMetric: e.key })}>
                <Menu.Item key="cosine">Cosine</Menu.Item>
                <Menu.Item key="dot product">Dot Product</Menu.Item>
              </Menu>
            } trigger={['click']}>
              <div className="dropdown-button">
                <span>{rerankingParams.similarityMetric}</span>
                <FiChevronDown className="dropdown-icon" />
              </div>
            </Dropdown>
          </div>

          <div className="section">
            <Text className="section-title">Max Chunks</Text>
            <InputNumber
              min={1}
              max={1000}
              value={rerankingParams.maxChunks}
              onChange={(value) => setRerankingParams({ ...rerankingParams, maxChunks: value })}
              className="input-field"
            />
          </div>

          <div className="section">
            <Text className="section-title">Threshold Score</Text>
            <InputNumber
              min={0}
              max={1}
              step={0.01}
              value={rerankingParams.thresholdScore}
              onChange={(value) => setRerankingParams({ ...rerankingParams, thresholdScore: value })}
              className="input-field"
            />
          </div>

          <div className="section">
            <Text className="section-title">Context Window Size</Text>
            <InputNumber
              min={0}
              max={2048}
              value={rerankingParams.contextWindowSize}
              onChange={(value) => setRerankingParams({ ...rerankingParams, contextWindowSize: value })}
              className="input-field"
            />
          </div>
        </Tabs.TabPane>
      </Tabs>
    </Card>
  );
};

// Node Component for React Flow
const RerankerModelStepNode = () => {
  return (
    <div style={{ backgroundColor: '#333', borderRadius: '5px' }}>
      <Handle
        type="target"
        position={Position.Left}
        style={{
          background: 'transparent',
          border: '2px solid #FFD700',
          borderRadius: '50%',
          width: '10px',
          height: '10px',
        }}
      />
      <Handle
        type="source"
        position={Position.Right}
        style={{
          background: 'transparent',
          border: '2px solid #FFD700',
          borderRadius: '50%',
          width: '10px',
          height: '10px',
        }}
      />
      <RerankerModelStep />
    </div>
  );
};

export default RerankerModelStepNode;
