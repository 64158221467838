import { notification } from "antd";
import { nodeTitleMap } from "../constants/nodeTitles";
import {
  dbCreationDag,
  fileNamesAtom,
  flowId,
  predictionDag,
  store,
  uid,
} from "../state/state";
import { dataApi } from "../services/apiHelpers";

export const getFlowIdFromName = async (flowName) => {
  const userId = store.get(uid);

  try {
    const res = await dataApi.post("/get_flow", {
      user_id: userId,
      flow_id: "",
    });
    const flows = res.data;
    const [fid] = Object.keys(flows).filter(
      (el) => flows[el].flow_name === flowName,
    );

    return fid;
  } catch (e) {
    console.log("Something went wrong");
    return false;
  }
};

const saveFlowOnServer = async (flowName, flowObject, flowType) => {
  const existingFlowId = await getFlowIdFromName(flowName);
  const fid = store.get(flowId);
  const dag =
    flowType === "creation"
      ? store.get(dbCreationDag)
      : store.get(predictionDag);

  if (flowName) store.set(flowId, existingFlowId ? existingFlowId : fid);

  const userId = store.get(uid);

  try {
    await dataApi.post("/save_flow", {
      user_id: userId,
      flow_id: fid,
      flow_name: flowName,
      flow_object: { ...flowObject, flowType: flowType, dag: dag },
    });
    notification.success({
      message: "Flow saved successfully!",
      description: "The flow has been saved.",
    });
  } catch (e) {
    notification.error({
      message: "Could not save flow.",
      description: "Something went wrong.",
    });
  }
};

export const saveFlow =
  (
    textFieldVal,
    setTextFieldVal,
    flows,
    setFlows,
    rfInstance,
    setActiveFlow,
    flowType,
  ) =>
  () => {
    const flowName = textFieldVal;
    if (!flowName) {
      notification.error({
        message: "Flow name not found!",
        description: "Flow name must not be empty.",
      });
    } else {
      const flow = rfInstance.toObject();
      saveFlowOnServer(flowName, flow, flowType);
      setFlows(flows.set(flowName, flow));
      setActiveFlow(flowName);
    }
  };

export const handleRestore =
  (setNodes, setEdges, setActiveFlow) =>
  async (flowName, flows, setTextFieldValue, setFid) => {
    const id = await getFlowIdFromName(flowName);
    const flow = flows[id].flow_object;
    const dag = flow.dag;
    if (flow.flowType === "creation") store.set(dbCreationDag, dag);
    else store.set(predictionDag, dag);

    setNodes(flow.nodes || []);
    setEdges(flow.edges || []);
    setActiveFlow(flowName);
    setTextFieldValue(flowName);
    setFid(id);
  };

export const onDropNode =
  (containerRef, stepsData, nodes, setNodes) => (event) => {
    event.preventDefault();
    const stepId = event.dataTransfer.getData("application/reactflow");
    if (stepId && containerRef.current) {
      const containerRect = containerRef.current.getBoundingClientRect();
      const dropX = event.clientX - containerRect.left;
      const dropY = event.clientY - containerRect.top;

      const foundStep = stepsData
        .flatMap((category) => category.steps)
        .find((s) => s.id === stepId);
      const fullName = foundStep ? foundStep.name : stepId;

      const newNode = {
        id: `${stepId}-${nodes.length}`,
        type: nodeTitleMap.get(fullName) ?? "default",
        position: { x: dropX, y: dropY },
        data: { label: fullName },
      };

      setNodes((nds) => [...nds, newNode]);
    }
  };

export const onDragOver = (event) => {
  event.preventDefault();
  event.dataTransfer.dropEffect = "move";
};

export const handleNodeClick =
  (stepsData, setSelectedStep) => (_event, node) => {
    const stepId = node.data.label;
    const step = stepsData
      .flatMap((category) => category.steps)
      .find((s) => s.name === stepId);
    if (step) {
      setSelectedStep(step);
    }
  };
