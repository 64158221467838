import React, { useState } from 'react';
import { Card, Input, InputNumber, Dropdown, Menu, Typography, Tabs, notification } from 'antd';
import { FiChevronDown, FiPlay, FiLoader } from 'react-icons/fi';
import { LuBrainCircuit } from 'react-icons/lu';
import { Handle, Position } from 'react-flow-renderer';

const { Text } = Typography;
const { TabPane } = Tabs;

const HallucinationShieldConfig = () => {
  const [modelName, setModelName] = useState('GPT 4o');
  const [temperature, setTemperature] = useState(0.7);
  const [maxTokens, setMaxTokens] = useState(1000);
  const [topP, setTopP] = useState(0.9);
  const [frequencyPenalty, setFrequencyPenalty] = useState(0.0);
  const [presencePenalty, setPresencePenalty] = useState(0.0);
  const [stopSequences, setStopSequences] = useState('');
  const [promptTemplate, setPromptTemplate] = useState('');
  const [loading, setLoading] = useState(false);

  const handleModelChange = (value) => {
    setModelName(value);
  };

  const modelMenu = (
    <Menu onClick={({ key }) => handleModelChange(key)}>
      <Menu.Item key="Llama-80B" className="dropdown-menu-item">Llama-80B</Menu.Item>
      <Menu.Item key="GPT 4o" className="dropdown-menu-item">GPT 4o</Menu.Item>
      <Menu.Item key="Claude" className="dropdown-menu-item">Claude</Menu.Item>
    </Menu>
  );

  const handleRun = () => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
      notification.success({
        message: 'Configuration Applied',
        description: 'Your hallucination shield configuration has been successfully applied.',
      });
    }, 2000);
  };

  return (
    <Card
      title={
        <div className="card-header">
          <LuBrainCircuit className="icon" />
          <span className="header-text">Hallucination Shield</span>
          <div onClick={handleRun} className="run-button">
            {loading ? (
              <FiLoader className="loader" spin />
            ) : (
              <FiPlay className="play" />
            )}
          </div>
        </div>
      }
      bordered={false}
      className="config-card"
    >
      <Tabs defaultActiveKey="1" className="config-tabs">
        <TabPane tab="Basic Settings" key="1">
          <div className="input-section">
            <div className="option-label">Model Name</div>
            <Dropdown overlay={modelMenu} trigger={['click']}>
              <div className="dropdown-button">
                <span>{modelName}</span>
                <FiChevronDown className="dropdown-icon" />
              </div>
            </Dropdown>
          </div>
          <br />
          <div className="input-section">
            <div className="option-label">Model Type : Generative</div>
          </div>

          <div className="input-section">
            <div className="option-label">Temperature</div>
            <InputNumber
              min={0}
              max={1}
              step={0.01}
              value={temperature}
              onChange={setTemperature}
              className="input-number"
            />
            <Text className="help-text">
              Controls the randomness of the output (0.0 for deterministic, 1.0 for more random).
            </Text>
          </div>

          <div className="input-section">
            <div className="option-label">Max Tokens</div>
            <InputNumber
              min={1}
              max={4096}
              value={maxTokens}
              onChange={setMaxTokens}
              className="input-number"
            />
            <Text className="help-text">The maximum number of tokens in the response.</Text>
          </div>

          <div className="input-section">
            <div className="option-label">Top P</div>
            <InputNumber
              min={0}
              max={1}
              step={0.01}
              value={topP}
              onChange={setTopP}
              className="input-number"
            />
            <Text className="help-text">
              Controls nucleus sampling (smallest set of tokens with a probability mass of top_p).
            </Text>
          </div>
        </TabPane>

        <TabPane tab="Advanced Settings" key="2">
          <div className="input-section">
            <div className="option-label">Frequency Penalty</div>
            <InputNumber
              min={0}
              max={1}
              step={0.01}
              value={frequencyPenalty}
              onChange={setFrequencyPenalty}
              className="input-number"
            />
            <Text className="help-text">Penalizes repeated tokens, encouraging diversity in the output.</Text>
          </div>

          <div className="input-section">
            <div className="option-label">Presence Penalty</div>
            <InputNumber
              min={0}
              max={1}
              step={0.01}
              value={presencePenalty}
              onChange={setPresencePenalty}
              className="input-number"
            />
            <Text className="help-text">Penalizes tokens that have already appeared, encouraging new topics.</Text>
          </div>

          <div className="input-section">
            <div className="option-label">Stop Sequences</div>
            <Input
              value={stopSequences}
              onChange={(e) => setStopSequences(e.target.value)}
              className="input-field"
            />
            <Text className="help-text">A list of tokens that will stop the model from generating further output.</Text>
          </div>

          <div className="input-section">
            <div className="option-label">Prompt Template</div>
            <Input
              value={promptTemplate}
              onChange={(e) => setPromptTemplate(e.target.value)}
              className="input-field"
            />
            <Text className="help-text">The template used to format the input query for the LLM.</Text>
          </div>
        </TabPane>
      </Tabs>
    </Card>
  );
};

const HallucinationShieldConfigNode = () => {
  return (
    <div style={{ borderRadius: '5px', padding: '10px' }}>
      <Handle
        type="target"
        position={Position.Left}
        style={{
          background: 'transparent',
          border: '2px solid #FFD700',
          borderRadius: '50%',
          width: '10px',
          height: '10px',
        }}
      />
      <Handle
        type="source"
        position={Position.Right}
        style={{
          background: 'transparent',
          border: '2px solid #FFD700',
          borderRadius: '50%',
          width: '10px',
          height: '10px',
        }}
      />
      <HallucinationShieldConfig />
    </div>
  );
};

export default HallucinationShieldConfigNode;
