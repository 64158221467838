import React, { useState, useMemo, useEffect } from "react";
import { Tabs } from "antd";
import {
  AppstoreOutlined,
  TableOutlined,
  UnorderedListOutlined,
  ArrowUpOutlined,
} from "@ant-design/icons";

import Button from "../components/ui/button";
import ApiGridView from "../components/Docs/ApiGridView";
import ApiListViewTable from "../components/Docs/ApiListViewTable";
import ApiDetailView from "../components/Docs/ApiDetailView";
import { documentationData } from "../constants/documentationData";
import { apiGroups } from "../constants/groups";
import SdkHeader from "../components/Header/SdkHeader";

const { TabPane } = Tabs;

const ApiDocs = () => {
  const [viewMode, setViewMode] = useState("grid");
  const [group, setGroup] = useState("all");
  const [selectedApiId, setSelectedApiId] = useState(null);
  const [showScrollTop, setShowScrollTop] = useState(false);

  const filteredData = useMemo(
    () =>
      group !== "all"
        ? documentationData.filter((el) => el.group === group)
        : documentationData,
    [group]
  );

  const handleApiClick = (apiId) => {
    setSelectedApiId(`api-${apiId}`);
    setViewMode("detail");
  };

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  useEffect(() => {
    const handleScroll = () => {
      setShowScrollTop(window.scrollY > window.innerHeight);
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  return (
    <>
      <SdkHeader />
      <section className="w-full min-h-screen p-8 lg:p-20 flex flex-col gap-2 lg:gap-8 bg-slate-800 mt-4 lg:mt-12 overflow-y-auto">
        <div className="flex w-full justify-between flex-wrap lg:flex-nowrap relative">
          {viewMode !== "detail" ? (
            <Tabs
              defaultActiveKey="all"
              onChange={setGroup}
              className="w-full lg:w-1/2"
            >
              <TabPane tab="All" key="all" />
              {apiGroups.map((el) => (
                <TabPane tab={el} key={el} />
              ))}
            </Tabs>
          ) : (
            <div className="w-full lg:w-1/2"></div>
          )}

          <div className="flex gap-4 flex-wrap justify-center lg:flex-nowrap lg:justify-end sticky top-20 z-10">
            <Button
              icon={<AppstoreOutlined />}
              type={viewMode === "grid" ? "primary" : "default"}
              placeholder="Grid View"
              onClick={() => setViewMode("grid")}
            />
            <Button
              icon={<TableOutlined />}
              type={viewMode === "list" ? "primary" : "default"}
              placeholder="Table View"
              onClick={() => setViewMode("list")}
            />
            <Button
              icon={<UnorderedListOutlined />}
              type={viewMode === "detail" ? "primary" : "default"}
              placeholder="Detail View"
              onClick={() => setViewMode("detail")}
            />
          </div>
        </div>

        {viewMode === "grid" && (
          <ApiGridView apis={filteredData} onApiClick={handleApiClick} />
        )}
        {viewMode === "list" && (
          <ApiListViewTable apis={filteredData} onApiClick={handleApiClick} />
        )}
        {viewMode === "detail" && (
          <ApiDetailView
            apis={documentationData}
            selectedApiId={selectedApiId}
          />
        )}

        {showScrollTop && (
          <button
            onClick={scrollToTop}
            className="fixed bottom-8 right-8 p-4 rounded-full bg-[#A5CA0D] text-white shadow-lg"
            aria-label="Scroll to Top"
          >
            <ArrowUpOutlined className="text-xl" />
          </button>
        )}
      </section>
    </>
  );
};

export default ApiDocs;
