import React, { useState } from 'react';
import { Card, InputNumber, Dropdown, Menu, Checkbox, Typography, notification, Tabs } from 'antd';
import { FiPlay, FiLoader, FiChevronDown } from 'react-icons/fi';
import { BsGear } from 'react-icons/bs';



import { Handle, Position } from 'react-flow-renderer';
const { Text } = Typography;


const HybridRetrievalConfig = () => {
    const [loading, setLoading] = useState(false);
    const [weightingStrategy, setWeightingStrategy] = useState({
      vectorWeight: 0.5,
      graphWeight: 0.5,
      normalization: 'min-max',
      scoreCombination: 'sum',
    });
    const [concatenationStrategy, setConcatenationStrategy] = useState({
      order: 'vector-first',
      includeDuplicates: false,
    });
  
    const normalizationMenu = (
      <Menu onClick={(e) => setWeightingStrategy({ ...weightingStrategy, normalization: e.key })}>
        <Menu.Item className="dropdown-menu-item" key="min-max">Min-Max</Menu.Item>
        <Menu.Item className="dropdown-menu-item" key="z-score">Z-Score</Menu.Item>
      </Menu>
    );
  
    const scoreCombinationMenu = (
      <Menu onClick={(e) => setWeightingStrategy({ ...weightingStrategy, scoreCombination: e.key })}>
        <Menu.Item className="dropdown-menu-item" key="sum">Sum</Menu.Item>
        <Menu.Item className="dropdown-menu-item" key="average">Average</Menu.Item>
      </Menu>
    );
  
    const orderMenu = (
      <Menu onClick={(e) => setConcatenationStrategy({ ...concatenationStrategy, order: e.key })}>
        <Menu.Item className="dropdown-menu-item" key="vector-first">Vector-First</Menu.Item>
        <Menu.Item className="dropdown-menu-item" key="knowledge-graph-first">Knowledge Graph-First</Menu.Item>
        <Menu.Item className="dropdown-menu-item" key="random">Random</Menu.Item>
      </Menu>
    );
  
    const handleRun = () => {
      setLoading(true);
      setTimeout(() => {
        setLoading(false);
        notification.success({
          message: 'Hybrid Retrieval Configuration Applied',
          description: 'Your hybrid retrieval configuration has been successfully applied.',
        });
      }, 2000);
    };
  
    return (
      <Card
        title={
          <div className="card-header">
            <div className="header-title">
              <BsGear className="icon" />
              <span className="header-text">Hybrid Retrieval Configuration</span>
            </div>
            <div onClick={handleRun} className="run-button">
              {loading ? (
                <FiLoader className="loader" spin />
              ) : (
                <FiPlay className="play" />
              )}
            </div>
          </div>
        }
        bordered={false}
        className="preprocessing-card"
      >
        <Tabs defaultActiveKey="1">
          <Tabs.TabPane tab="Weighting Strategy" key="1">
            <div className="section">
              <Text className="section-title">Vector Database Weight</Text>
              <InputNumber
                min={0}
                max={1}
                step={0.1}
                value={weightingStrategy.vectorWeight}
                onChange={(value) => setWeightingStrategy({ ...weightingStrategy, vectorWeight: value })}
                className="input-field"
              />
            </div>
            <div className="section">
              <Text className="section-title">Knowledge Graph Weight</Text>
              <InputNumber
                min={0}
                max={1}
                step={0.1}
                value={weightingStrategy.graphWeight}
                onChange={(value) => setWeightingStrategy({ ...weightingStrategy, graphWeight: value })}
                className="input-field"
              />
            </div>
            <div className="section">
              <Text className="section-title">Normalization Method</Text>
              <Dropdown overlay={normalizationMenu} trigger={['click']}>
                <div className="dropdown-button">
                  <span>{weightingStrategy.normalization}</span>
                  <FiChevronDown className="dropdown-icon" />
                </div>
              </Dropdown>
            </div>
            <div className="section">
              <Text className="section-title">Score Combination Method</Text>
              <Dropdown overlay={scoreCombinationMenu} trigger={['click']}>
                <div className="dropdown-button">
                  <span>{weightingStrategy.scoreCombination}</span>
                  <FiChevronDown className="dropdown-icon" />
                </div>
              </Dropdown>
            </div>
          </Tabs.TabPane>
  
          <Tabs.TabPane tab="Concatenation Strategy" key="2">
            <div className="section">
              <Text className="section-title">Order of Results</Text>
              <Dropdown overlay={orderMenu} trigger={['click']}>
                <div className="dropdown-button">
                  <span>{concatenationStrategy.order}</span>
                  <FiChevronDown className="dropdown-icon" />
                </div>
              </Dropdown>
            </div>
            <div className="section">
              <Text className="section-title">Include Duplicates</Text>
              &nbsp;
              <Checkbox
                checked={concatenationStrategy.includeDuplicates}
                onChange={(e) =>
                  setConcatenationStrategy({ ...concatenationStrategy, includeDuplicates: e.target.checked })
                }
                className="checkbox-field"
              >
               
              </Checkbox>
            </div>
          </Tabs.TabPane>
        </Tabs>
      </Card>
    );
  };
  
const HybridRetrievalConfigNode = () => {
    return (
        <div style={{ backgroundColor: '#333', borderRadius: '5px' }}>
            <Handle
                type="target"
                position={Position.Left}
                style={{
                    background: 'transparent',
                    border: '2px solid #FFD700',
                    borderRadius: '50%',
                    width: '10px',
                    height: '10px',
                }}
            />
            <Handle
                type="source"
                position={Position.Right}
                style={{
                    background: 'transparent',
                    border: '2px solid #FFD700',
                    borderRadius: '50%',
                    width: '10px',
                    height: '10px',
                }}
            />
            <HybridRetrievalConfig />
        </div>
    );
};

export default HybridRetrievalConfigNode;
