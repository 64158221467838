import React, { useState } from 'react';
import { Card, Input, InputNumber, Switch, Typography, Tabs, notification } from 'antd';
import { LuBrainCircuit } from 'react-icons/lu';
import { FiPlay, FiLoader } from 'react-icons/fi';

const { Text } = Typography;
const { TabPane } = Tabs;

const RealTimeAnalyticsConfig = () => {
  // State variables for each setting
  const [userQueryTracking, setUserQueryTracking] = useState(true);
  const [trackingMethod, setTrackingMethod] = useState('database');
  const [queryMetadata, setQueryMetadata] = useState('userID, timestamp');
  const [responseTimeMonitoring, setResponseTimeMonitoring] = useState(true);
  const [trackingInterval, setTrackingInterval] = useState(10);
  const [responseTimeThreshold, setResponseTimeThreshold] = useState(500);
  const [queryVolume, setQueryVolume] = useState(true);
  const [queryVolumeWindow, setQueryVolumeWindow] = useState('per minute');
  const [errorRate, setErrorRate] = useState(true);
  const [errorRateWindow, setErrorRateWindow] = useState('per minute');
  const [errorThreshold, setErrorThreshold] = useState(0.05);
  const [topQueries, setTopQueries] = useState(true);
  const [topN, setTopN] = useState(10);
  const [topQueriesWindow, setTopQueriesWindow] = useState('per day');
  const [latencyMetrics, setLatencyMetrics] = useState(true);
  const [component, setComponent] = useState('retrieval');
  const [latencyThreshold, setLatencyThreshold] = useState(300);
  const [successRate, setSuccessRate] = useState(true);
  const [successRateWindow, setSuccessRateWindow] = useState('per minute');
  const [successThreshold, setSuccessThreshold] = useState(0.95);
  const [usageTrends, setUsageTrends] = useState(true);
  const [trendDuration, setTrendDuration] = useState('weekly');
  const [metricsTracked, setMetricsTracked] = useState('query volume, response time, error rate');
  
  const [loading, setLoading] = useState(false); // Loading state

  const handleRun = () => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
      notification.success({
        message: 'Configuration Applied',
        description: 'Your real-time analytics configuration has been successfully applied.',
      });
    }, 2000); // Simulate an API call
  };

  return (
    <Card
      title={
        <div className="card-header">
          <LuBrainCircuit className="icon" />
          <span className="header-text">Real-time Analytics</span>
          <div onClick={handleRun} className="run-button" style={{ marginLeft: 'auto', cursor: 'pointer' }}>
            {loading ? (
              <FiLoader className="loader" spin />
            ) : (
              <FiPlay className="play" />
            )}
          </div>
        </div>
      }
      bordered={false}
      className="config-card"
    >
      <Tabs defaultActiveKey="1" className="config-tabs">
        {/* General Settings Tab */}
        <TabPane tab="General Settings" key="1">
          <div style={{ maxHeight: '400px', overflowY: 'auto', padding: '10px' }}>
            <div className="input-section">
              <Switch
                checked={userQueryTracking}
                onChange={(checked) => setUserQueryTracking(checked)}
                style={{
                  backgroundColor: 'gold',
                  borderColor: 'gold',
                }}
                className="switch-toggle"
              />
              <Text className="option-label">User Query Tracking</Text>
              <br></br>
              <Text className="help-text">Whether to track user queries.</Text>
            </div>
            {userQueryTracking && (
              <>
                <div className="input-section">
                  <div className="option-label">Tracking Method</div>
                  <Input
                    value={trackingMethod}
                    onChange={(e) => setTrackingMethod(e.target.value)}
                    className="input-field"
                  />
                  <Text className="help-text">Method to log queries (e.g., database, file).</Text>
                </div>
                <div className="input-section">
                  <div className="option-label">Query Metadata</div>
                  <Input
                    value={queryMetadata}
                    onChange={(e) => setQueryMetadata(e.target.value)}
                    className="input-field"
                  />
                  <Text className="help-text">Additional metadata to store (e.g., userID, timestamp).</Text>
                </div>
              </>
            )}

            <div className="input-section">
              <Switch
                checked={queryVolume}
                onChange={(checked) => setQueryVolume(checked)}
                style={{
                  backgroundColor: 'gold',
                  borderColor: 'gold',
                }}
                className="switch-toggle"
              />
              <Text className="option-label">Query Volume Tracking</Text>
              <br></br>
              <Text className="help-text">Whether to track the total number of queries.</Text>
            </div>
            {queryVolume && (
              <div className="input-section">
                <div className="option-label">Time Window</div>
                <Input
                  value={queryVolumeWindow}
                  onChange={(e) => setQueryVolumeWindow(e.target.value)}
                  className="input-field"
                />
                <Text className="help-text">Time period for tracking query volume (e.g., per minute).</Text>
              </div>
            )}

            <div className="input-section">
              <Switch
                checked={responseTimeMonitoring}
                onChange={(checked) => setResponseTimeMonitoring(checked)}
                style={{
                  backgroundColor: 'gold',
                  borderColor: 'gold',
                }}
                className="switch-toggle"
              />
              <Text className="option-label">Response Time Monitoring</Text>
              <br></br>
              <Text className="help-text">Whether to track response times.</Text>
            </div>
            {responseTimeMonitoring && (
              <>
                <div className="input-section">
                  <div className="option-label">Tracking Interval (seconds)</div>
                  <InputNumber
                    min={1}
                    value={trackingInterval}
                    onChange={setTrackingInterval}
                    className="input-number"
                  />
                  <Text className="help-text">Frequency of monitoring (in seconds).</Text>
                </div>
                <div className="input-section">
                  <div className="option-label">Response Time Threshold (ms)</div>
                  <InputNumber
                    min={1}
                    value={responseTimeThreshold}
                    onChange={setResponseTimeThreshold}
                    className="input-number"
                  />
                  <Text className="help-text">Time limit to flag slow responses (in milliseconds).</Text>
                </div>
              </>
            )}
          </div>
        </TabPane>

        {/* Advanced Settings Tab */}
        <TabPane tab="Advanced Settings" key="2">
          <div style={{ maxHeight: '400px', overflowY: 'auto', padding: '10px' }}>
            <div className="input-section">
              <Switch
                checked={errorRate}
                onChange={(checked) => setErrorRate(checked)}
                style={{
                  backgroundColor: 'gold',
                  borderColor: 'gold',
                }}
                className="switch-toggle"
              />
              <Text className="option-label">Error Rate Monitoring</Text>
              <br></br>
              <Text className="help-text">Whether to monitor error rates.</Text>
            </div>
            {errorRate && (
              <>
                <div className="input-section">
                  <div className="option-label">Error Calculation Window</div>
                  <Input
                    value={errorRateWindow}
                    onChange={(e) => setErrorRateWindow(e.target.value)}
                    className="input-field"
                  />
                  <Text className="help-text">Time period over which to calculate error rates (e.g., per minute).</Text>
                </div>
                <div className="input-section">
                  <div className="option-label">Error Threshold (%)</div>
                  <InputNumber
                    min={0}
                    max={1}
                    step={0.01}
                    value={errorThreshold}
                    onChange={setErrorThreshold}
                    className="input-number"
                  />
                  <Text className="help-text">Maximum acceptable error rate (e.g., 0.05 for 5%).</Text>
                </div>
              </>
            )}

            {/* Similar sections for other advanced settings */}
          </div>
        </TabPane>
      </Tabs>
    </Card>
  );
};

export default RealTimeAnalyticsConfig;
