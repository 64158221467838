export const documentationData = [
  {
    id: "getConversationId",
    apiName: "Conversation ID Fetch",
    method: "GET",
    route: "/api/getConversationId",
    group: "Data Ingestion",
    description:
      "Fetches the unique conversation ID to track conversations. Typically used for creating a new chat session.",
    request: {},
    response: {
      conversationId: "12345",
    },
  },
  {
    id: "vectorDatabaseInsert",
    apiName: "Vector Database Insert",
    method: "POST",
    route: "/api/vector_database",
    group: "Knowledge",
    description:
      "Inserts a conversation's data into the vector database for further processing and semantic search.",
    request: {
      conversationData: "Sample text data...",
    },
    response: {
      success: true,
      id: "56789",
    },
  },
  {
    id: "pdfUpload",
    apiName: "PDF Upload",
    method: "POST",
    route: "/api/uploadFile",
    group: "Data Ingestion",
    description:
      "Uploads a PDF file and associates it with the current conversation for analysis and processing.",
    request: {
      file: "Encoded PDF content",
    },
    response: {
      success: true,
      fileId: "98765",
    },
  },
  {
    id: "dataProcessing",
    apiName: "Data Processing",
    method: "POST",
    route: "/api/data_processing",
    group: "Data Ingestion",
    description:
      "Processes the uploaded data by removing stop words, applying lemmatization, or named entity recognition.",
    request: {
      text: "Here is some raw text to process.",
    },
    response: {
      success: true,
      processedText: "Here is some processed text.",
    },
  },
  {
    id: "chunking",
    apiName: "Chunking",
    method: "POST",
    route: "/api/chunking",
    group: "Knowledge",
    description:
      "Chunks the uploaded data into smaller pieces for efficient semantic or sentence-level processing.",
    request: {
      text: "Here is some long text to chunk into smaller pieces.",
    },
    response: {
      success: true,
      chunks: ["Here is some", "long text to", "chunk into", "smaller pieces"],
    },
  },
  {
    id: "vectorDatabaseCreation",
    apiName: "Vector Database Creation",
    method: "POST",
    route: "/api/create_vector_db",
    group: "Knowledge",
    description:
      "Creates a new vector database instance based on specified parameters like chunk size and data processing type.",
    request: {
      parameters: {
        chunkSize: "1000",
        type: "semantic",
      },
    },
    response: {
      success: true,
      databaseId: "db123",
    },
  },
  {
    id: "knowledgeGraphConstruction",
    apiName: "Knowledge Graph Construction",
    method: "POST",
    route: "/api/create_knowledge_graph",
    group: "Knowledge",
    description:
      "Constructs a knowledge graph from existing data points to facilitate better query responses.",
    request: {
      dataPoints: ["data1", "data2"],
    },
    response: {
      success: true,
      graphId: "kg123",
    },
  },
  {
    id: "knowledgeRetrieval",
    apiName: "Knowledge Retrieval",
    method: "POST",
    route: "/api/get_knowledge",
    group: "Knowledge",
    description:
      "Retrieves information from the knowledge graph based on query parameters.",
    request: {
      query: "Retrieve all nodes connected to 'data1'.",
    },
    response: {
      success: true,
      data: ["node1", "node2"],
    },
  },
  {
    id: "bigModels",
    apiName: "Big Models",
    method: "POST",
    route: "/api/big_models",
    group: "Intelligence",
    description:
      "Trains or utilizes large AI models for complex data analysis and predictions.",
    request: {
      data: "Large dataset for model training",
    },
    response: {
      success: true,
      modelId: "model123",
    },
  },
  {
    id: "mediumModels",
    apiName: "Medium Models",
    method: "POST",
    route: "/api/medium_models",
    group: "Intelligence",
    description:
      "Handles medium-sized models that balance performance and resource usage.",
    request: {
      data: "Medium dataset for model training",
    },
    response: {
      success: true,
      modelId: "model456",
    },
  },
  {
    id: "hallucinationShield",
    apiName: "Hallucination shield",
    method: "POST",
    route: "/api/hallucination_shield",
    group: "Boosting Tools",
    description:
      "Prevents AI models from generating nonsensical or unrelated outputs during tasks.",
    request: {
      input: "Text data for processing",
    },
    response: {
      success: true,
      shieldedOutput: "Processed text data with reduced hallucinations",
    },
  },
  {
    id: "queryNormalization",
    apiName: "Query normalization",
    method: "POST",
    route: "/api/query_normalization",
    group: "Boosting Tools",
    description:
      "Normalizes queries to ensure consistent responses from AI models regardless of input variations.",
    request: {
      query: "Unformatted user query",
    },
    response: {
      success: true,
      normalizedQuery: "Formatted user query",
    },
  },
  {
    id: "answerRefinement",
    apiName: "Answer refinement",
    method: "POST",
    route: "/api/answer_refinement",
    group: "Boosting Tools",
    description:
      "Refines the outputs of AI models to increase accuracy and relevance to the query.",
    request: {
      query: "Initial model response",
      context: "Relevant context information",
    },
    response: {
      success: true,
      refinedAnswer: "Enhanced and context-aware answer",
    },
  },
  {
    id: "postGenerationHallucinationShield",
    apiName: "Post generation Hallucination Shield",
    method: "POST",
    route: "/api/post_generation_hallucination_shield",
    group: "Boosting Tools",
    description:
      "Applies additional filtering to AI outputs to ensure accuracy and reduce errors post-generation.",
    request: {
      generatedText: "AI-generated text",
    },
    response: {
      success: true,
      safeText: "Filtered AI-generated text",
    },
  },
  {
    id: "reranker",
    apiName: "Reranker",
    method: "POST",
    route: "/api/reranker",
    group: "Boosting Tools",
    description:
      "Ranks multiple AI-generated answers to select the most appropriate one based on contextual cues.",
    request: {
      answers: ["Answer 1", "Answer 2", "Answer 3"],
      question: "User's question",
    },
    response: {
      success: true,
      bestAnswer: "Answer 1",
    },
  },
  {
    id: "errorHandling",
    apiName: "Error Handling",
    method: "POST",
    route: "/api/error_handling",
    group: "Stability and Monitoring",
    description:
      "Handles errors and exceptions in AI operations to maintain system stability and performance.",
    request: {
      errorLog: "Details of the encountered error",
    },
    response: {
      success: true,
      resolutionStatus: "Error resolved",
    },
  },
  {
    id: "realtimeAnalysis",
    apiName: "Realtime Analysis",
    method: "POST",
    route: "/api/realtime_analysis",
    group: "Stability and Monitoring",
    description:
      "Performs real-time analysis of data to provide immediate insights and responses.",
    request: {
      inputData: "Streaming data for analysis",
    },
    response: {
      success: true,
      analysisResults: "Immediate analytical results",
    },
  },
  {
    id: "queryVectorDatabase",
    apiName: "Query Vector Database",
    method: "POST",
    route: "/api/querying",
    group: "Knowledge",
    description:
      "Queries the vector database using a semantic or MMR search to retrieve relevant information.",
    request: {
      searchQuery: "Semantic search terms",
    },
    response: {
      success: true,
      results: ["Data point 1", "Data point 2"],
    },
  },
  {
    id: "answerGeneration",
    apiName: "Answer Generation",
    method: "POST",
    route: "/api/generate_answer",
    group: "Intelligence",
    description:
      "Generates an answer based on the provided query and conversation data, using semantic search techniques.",
    request: {
      query: "User query for information",
      context: "Additional context to refine the answer",
    },
    response: {
      success: true,
      answer: "Generated answer based on query and context",
    },
  },
];
