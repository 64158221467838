import React, { useState } from 'react';
import { Card, InputNumber, Dropdown, Menu, Input, Checkbox, Typography, notification, Tabs } from 'antd';
import { FiPlay, FiLoader, FiChevronDown } from 'react-icons/fi';
import { BsExclamationCircle } from 'react-icons/bs';
import { Handle, Position } from 'react-flow-renderer';

const { Text } = Typography;

const ErrorHandlingConfig = () => {
  const [loading, setLoading] = useState(false);

  const [retryMechanism, setRetryMechanism] = useState({
    enabled: true,
    maxRetries: 3,
    retryDelay: 5,
  });

  const [timeoutSetting, setTimeoutSetting] = useState({
    duration: null,
  });

  const [fallback, setFallback] = useState({
    enabled: false,
    defaultAnswer: '',
  });

  const [errorLogging, setErrorLogging] = useState({
    enabled: false,
    logLocation: '',
  });

  const [validation, setValidation] = useState({
    enabled: false,
    criteria: {},
  });

  const handleRun = () => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
      notification.success({
        message: 'Error Handling Configuration Applied',
        description: 'Your error handling configuration has been successfully applied.',
      });
    }, 2000);
  };

  return (
    <Card
      title={
        <div className="card-header">
          <div className="header-title">
            <BsExclamationCircle className="icon" />
            <span className="header-text">Error Handling Configuration</span>
          </div>
          <div onClick={handleRun} className="run-button">
            {loading ? (
              <FiLoader className="loader" spin />
            ) : (
              <FiPlay className="play" />
            )}
          </div>
        </div>
      }
      bordered={false}
      className="error-handling-card"
    >
      <Tabs defaultActiveKey="1">
        <Tabs.TabPane tab="Retry Mechanism" key="1">
          <div className="section">
            <Text className="section-title">Enabled</Text>
            &nbsp;
            <Checkbox
              checked={retryMechanism.enabled}
              onChange={(e) =>
                setRetryMechanism({ ...retryMechanism, enabled: e.target.checked })
              }
              className="checkbox-field"
            />
          </div>
          <div className="section">
            <Text className="section-title">Max Retries</Text>
            <InputNumber
              min={0}
              max={10}
              value={retryMechanism.maxRetries}
              onChange={(value) =>
                setRetryMechanism({ ...retryMechanism, maxRetries: value })
              }
              className="input-field"
            />
          </div>
          <div className="section">
            <Text className="section-title">Retry Delay (in seconds)</Text>
            <InputNumber
              min={1}
              max={60}
              value={retryMechanism.retryDelay}
              onChange={(value) =>
                setRetryMechanism({ ...retryMechanism, retryDelay: value })
              }
              className="input-field"
            />
          </div>
        </Tabs.TabPane>

        <Tabs.TabPane tab="Timeout Setting" key="2">
          <div className="section">
            <Text className="section-title">Timeout Duration (in seconds)</Text>
            <InputNumber
              min={1}
              value={timeoutSetting.duration}
              onChange={(value) =>
                setTimeoutSetting({ ...timeoutSetting, duration: value })
              }
              className="input-field"
            />
          </div>
        </Tabs.TabPane>

        <Tabs.TabPane tab="Fallback" key="3">
          <div className="section">
            <Text className="section-title">Enabled</Text>
            &nbsp;
            <Checkbox
              checked={fallback.enabled}
              onChange={(e) =>
                setFallback({ ...fallback, enabled: e.target.checked })
              }
              className="checkbox-field"
            />
          </div>
          <div className="section">
            <Text className="section-title">Default Answer</Text>
            <Input
              value={fallback.defaultAnswer}
              onChange={(e) =>
                setFallback({ ...fallback, defaultAnswer: e.target.value })
              }
              className="input-field"
            />
          </div>
        </Tabs.TabPane>

        <Tabs.TabPane tab="Error Logging" key="4">
          <div className="section">
            <Text className="section-title">Enabled</Text>
            &nbsp;
            <Checkbox
              checked={errorLogging.enabled}
              onChange={(e) =>
                setErrorLogging({ ...errorLogging, enabled: e.target.checked })
              }
              className="checkbox-field"
            />
          </div>
          <div className="section">
            <Text className="section-title">Log Location</Text>
            <Input
              value={errorLogging.logLocation}
              onChange={(e) =>
                setErrorLogging({ ...errorLogging, logLocation: e.target.value })
              }
              className="input-field"
            />
          </div>
        </Tabs.TabPane>

        <Tabs.TabPane tab="Validation" key="5">
          <div className="section">
            <Text className="section-title">Enabled</Text>
            &nbsp;
            <Checkbox
              checked={validation.enabled}
              onChange={(e) =>
                setValidation({ ...validation, enabled: e.target.checked })
              }
              className="checkbox-field"
            />
          </div>
          <div className="section">
            <Text className="section-title">Validation Criteria</Text>
            <Input
              placeholder="Enter criteria in JSON format"
              value={JSON.stringify(validation.criteria)}
              onChange={(e) => {
                try {
                  setValidation({
                    ...validation,
                    criteria: JSON.parse(e.target.value),
                  });
                } catch (err) {
                  // Handle invalid JSON
                }
              }}
              className="input-field"
            />
          </div>
        </Tabs.TabPane>
      </Tabs>
    </Card>
  );
};

// Node Component for React Flow
const ErrorHandlingConfigNode = () => {
  return (
    <div style={{ borderRadius: '5px' }}>
      <Handle
        type="target"
        position={Position.Left}
        style={{
          background: 'transparent',
          border: '2px solid #FFD700',
          borderRadius: '50%',
          width: '10px',
          height: '10px',
        }}
      />
      <Handle
        type="source"
        position={Position.Right}
        style={{
          background: 'transparent',
          border: '2px solid #FFD700',
          borderRadius: '50%',
          width: '10px',
          height: '10px',
        }}
      />
      <ErrorHandlingConfig />
    </div>
  );
};

export default ErrorHandlingConfigNode;
