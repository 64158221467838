import React, { useState } from "react";
import { CopyOutlined, ExportOutlined } from "@ant-design/icons";
import { Table, Tooltip, Button } from "antd";

const ApiListViewTable = ({ apis, onApiClick }) => {
  const [tooltipText, setTooltipText] = useState("Copy to clipboard");

  const handleCopy = (text) => {
    navigator.clipboard.writeText(text).then(() => {
      setTooltipText("Route copied!");
      setTimeout(() => setTooltipText("Copy to clipboard"), 2000);
    });
  };

  const getMethodColor = (method) => {
    switch (method) {
      case "GET":
        return "#3B82F6";
      case "POST":
        return "#22C55E";
      case "PUT":
        return "#EAB308";
      default:
        return "#EF4444";
    }
  };

  const columns = [
    {
      title: "API Name",
      dataIndex: "apiName",
      key: "apiName",
      render: (text) => <strong>{text}</strong>,
    },
    {
      title: "Method",
      dataIndex: "method",
      key: "method",
      render: (method) => (
        <span
          style={{
            fontWeight: 500,
            color: getMethodColor(method),
          }}
        >
          {method}
        </span>
      ),
    },
    {
      title: "Route",
      dataIndex: "route",
      key: "route",
      render: (route) => (
        <Tooltip title={tooltipText}>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              cursor: "pointer",
            }}
          >
            <span>{route}</span>
            <Button
              icon={<CopyOutlined />}
              size="small"
              onClick={() => handleCopy(route)}
            />
          </div>
        </Tooltip>
      ),
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
    },
    {
      title: "Details",
      dataIndex: "details",
      key: "details",
      render: (text, record) => (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Button
            icon={<ExportOutlined />}
            size="small"
            onClick={() => onApiClick(record.id)}
          />
        </div>
      ),
    },
  ];

  return (
    <section
      style={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <div style={{ overflowX: "auto" }}>
        <Table
          columns={columns}
          dataSource={apis.map((api) => ({
            id: api.id,
            key: api.id,
            apiName: api.apiName,
            method: api.method,
            route: api.route,
            description: api.description,
          }))}
          pagination={{ pageSize: 10 }}
        />
      </div>
    </section>
  );
};

export default ApiListViewTable;
