import React, { useEffect, useState } from "react";
import { Card, Checkbox, Typography, notification, Tabs } from "antd";
import { dbCreationDag } from "../state/state";
import { FiPlay, FiLoader } from "react-icons/fi";
import { LuBrainCircuit } from "react-icons/lu";

import { BsDatabaseGear } from "react-icons/bs";
import { useAtom } from "jotai";

const { Text } = Typography;
const { TabPane } = Tabs;

const DataPreprocessingCard = () => {
  const [textCleaningOptions, setTextCleaningOptions] = useState([]);
  const [formattingOptions, setFormattingOptions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [nodeId, setNodeId] = useState("data_preprocessing_1");
  const [dbDag, setdbDag] = useAtom(dbCreationDag);

  const textCleaningOptionsList = [
    "Remove Special Characters",
    "Remove HTML Tags",
    "Remove Extra Whitespace",
    "Remove Stop Words",
    "Remove Numbers",
    "Remove Non-ASCII Characters",
    "Remove URLs",
    "Remove Emojis",
    "Remove Duplicate Lines",
  ];

  const formattingOptionsList = [
    "Convert to Lowercase",
    "Sentence Case",
    "Normalize Punctuation",
    "Add/Remove Line Breaks",
    "Replace Tabs with Spaces",
    "Trim Leading/Trailing Spaces",
    "Collapse Multiple Line Breaks into One",
    "Standardize Quotation Marks",
    "Normalize Spacing Around Punctuation",
  ];

  const handleRun = () => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
      notification.success({
        message: "Data Preprocessing Applied",
        description:
          "Your data preprocessing configuration has been successfully applied.",
      });
    }, 2000);
  };

  useEffect(() => {
    const nodeCount = dbDag.filter(
      (el) => el.node === "data_preprocessing",
    ).length;
    const id = `data_preprocessing_${nodeCount + 1}`;
    setNodeId(id);
    const val = {
      id: id,
      node: "data_preprocessing",
      properties: [],
    };
    setdbDag([...dbDag, val]);
  }, []);

  const updateProperties = (props) => {
    const node = dbDag.find((el) => el.id === nodeId);
    const p = node?.properties ? node.properties : [];
    const val = {
      id: nodeId,
      node: "data_preprocessing",
      properties: [...new Set(p.concat(props))],
    };
    setdbDag(dbDag.filter((el) => el !== node).concat(val));
  };

  return (
    <Card
      title={
        <div className="card-header">
          <div className="header-title">
            <BsDatabaseGear class="icon" />
            <span className="header-text">Data Preprocessing</span>
          </div>
          <div onClick={handleRun} className="run-button">
            {loading ? (
              <FiLoader className="loader" spin />
            ) : (
              <FiPlay className="play" />
            )}
          </div>
        </div>
      }
      bordered={false}
      className="preprocessing-card"
    >
      <Tabs defaultActiveKey="1">
        <TabPane tab="Text Cleaning" key="1">
          <div className="section">
            <Checkbox.Group
              options={textCleaningOptionsList}
              value={textCleaningOptions}
              onChange={(checkedValues) => {
                setTextCleaningOptions(checkedValues);
                updateProperties(checkedValues);
              }}
              className="checkbox-group"
            />
          </div>
        </TabPane>

        <TabPane tab="Formatting" key="2">
          <div className="section">
            <Checkbox.Group
              options={formattingOptionsList}
              value={formattingOptions}
              onChange={(checkedValues) => {
                setFormattingOptions(checkedValues);
                updateProperties(checkedValues);
              }}
              className="checkbox-group"
            />
          </div>
        </TabPane>
      </Tabs>
    </Card>
  );
};

export default DataPreprocessingCard;
