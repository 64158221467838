import React, { useState } from 'react';
import styles from './TwoCards.module.css'; // Import CSS module for styling
import ChevronUpIcon from '@mui/icons-material/KeyboardArrowUp'; // Import Chevron Up Icon
import ChevronDownIcon from '@mui/icons-material/KeyboardArrowDown'; // Import Chevron Down Icon

const TwoCards = () => {
  // State to control minimize/maximize for both cards
  const [areCardsMinimized, setAreCardsMinimized] = useState(false);

  // Toggle minimize/maximize for both cards
  const toggleMinimizeCards = () => {
    setAreCardsMinimized((prev) => !prev);
  };

  return (
    <div className={styles.cardsWrapper}>
      {/* Cards Container */}
      <div className={styles.cardsContainer}>
        {/* Code Card */}
        <div className={`${styles.card} ${areCardsMinimized ? styles.cardMinimized : ''}`}>
        <div className={styles.cardHeader} style={{ display: 'flex', alignItems: 'center' }}>
  <span className={styles.icon} onClick={toggleMinimizeCards}>
    {/* Show chevron icon based on state */}
    {areCardsMinimized ? <ChevronDownIcon /> : <ChevronUpIcon />}
  </span>
  <span style={{ marginLeft: '8px', marginTop:'-7px' }}>Code</span>
</div>

          {!areCardsMinimized && (
            <div className={styles.cardBody}>
              <pre className={styles.code}>
                {`var view = CGRect(x: 0, y: 0, width: 58, height: 21)
view.textColor = UIColor(red: 1, green: 1, blue: 1, alpha: 1)
view.font = UIFont(name: "TTFirsNeue-Medium", size: 16)
view.textAlignment = .center`}
              </pre>
            </div>
          )}
        </div>

        {/* Debug Card */}
        <div className={`${styles.card} ${areCardsMinimized ? styles.cardMinimized : ''}`}>
          <div className={styles.cardHeader}>
            <span className={styles.icon} onClick={toggleMinimizeCards}>
              {/* Same icon as in the Code Card */}
              {areCardsMinimized ? <ChevronDownIcon /> : <ChevronUpIcon />}
            </span>
            <span style={{ marginLeft: '8px', marginTop:'-7px' }}>Debug</span>
          </div>
          {!areCardsMinimized && (
            <div className={styles.cardBody}>
              <pre className={styles.code}>
                {`[12:11:43:920] Debug started...
view.frame = CGRect(x: 0, y: 0, width: 56, height: 56)
view.layer.backgroundColor = UIColor(red: 0.163, green: 0.174, blue: 0.196, alpha: 1)
view.layer.borderColor = UIColor(red: 1, green: 1, blue: 1, alpha: 0.5).cgColor`}
              </pre>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default TwoCards;
