import React, { useEffect, useState } from "react";
import { availableDbs, dbCreationDag, flowId, uid } from "../state/state";
import {
  Card,
  Dropdown,
  Menu,
  Typography,
  Tabs,
  notification,
  Input,
} from "antd";
import { FiChevronDown, FiPlay, FiLoader } from "react-icons/fi";
import { LuBrainCircuit } from "react-icons/lu";
import { Handle, Position } from "react-flow-renderer";
import { useAtom, useAtomValue } from "jotai";
import { Button } from "antd";
import { dataApi } from "../services/apiHelpers";
import Loader from "./Loader";

const { Text } = Typography;
const { TabPane } = Tabs;

const VectorDatabaseConfig = () => {
  const [vectorDatabase, setVectorDatabase] = useState("Chroma DB");
  const [embeddingModel, setEmbeddingModel] = useState("all-mpnet-base-v2");
  const [indexType, setIndexType] = useState("Flat");
  const [loading, setLoading] = useState(false); // Loading state
  const [dbName, setDbName] = useState("");
  const [dbNameField, setDbNameField] = useState("");
  const [dbDag, setdbDag] = useAtom(dbCreationDag);
  const [dbs, setDbs] = useAtom(availableDbs);
  const userId = useAtomValue(uid);
  const fid = useAtomValue(flowId);

  const handleVectorDatabaseChange = (value) => {
    setVectorDatabase(value);
  };

  const handleEmbeddingModelChange = (value) => {
    setEmbeddingModel(value);
    updateProperties({ embedding_model: value });
  };

  const handleIndexTypeChange = (value) => {
    setIndexType(value);
    updateProperties({ index_type: value });
  };

  const handleNameChange = (e) => {
    const node = dbDag.find((el) => el.name === dbName);
    const name = e.target.value;
    setDbName(name);
    setDbNameField(name);
    const updatedNode = { ...node, name: name };
    setdbDag(dbDag.filter((el) => el !== node).concat(updatedNode));
  };

  const handleRun = () => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
      notification.success({
        message: "Configuration Applied",
        description:
          "Your vector database configuration has been successfully applied.",
      });
    }, 2000); // Simulate an API call
  };

  const vectorDatabaseMenu = (
    <Menu onClick={({ key }) => handleVectorDatabaseChange(key)}>
      <Menu.Item key="FAISS" className="dropdown-menu-item">
        FAISS
      </Menu.Item>
      <Menu.Item key="Chroma DB" className="dropdown-menu-item">
        Chroma DB
      </Menu.Item>
      <Menu.Item key="Milvus" className="dropdown-menu-item">
        Milvus
      </Menu.Item>
      <Menu.Item key="Pinecone" className="dropdown-menu-item">
        Pinecone
      </Menu.Item>
    </Menu>
  );

  const embeddingModelMenu = (
    <Menu onClick={({ key }) => handleEmbeddingModelChange(key)}>
      <Menu.Item key="text-embedding-ada-002" className="dropdown-menu-item">
        OpenAI Embeddings
      </Menu.Item>
      <Menu.Item key="all-mpnet-base-v2" className="dropdown-menu-item">
        Sentence Transformers: all-mpnet-base-v2
      </Menu.Item>
      <Menu.Item key="all-MiniLM-L12-v2" className="dropdown-menu-item">
        Sentence Transformers: all-MiniLM-L12-v2
      </Menu.Item>
      <Menu.Item key="Cohere Embeddings" className="dropdown-menu-item">
        Cohere Embeddings
      </Menu.Item>
      <Menu.Item key="GTE" className="dropdown-menu-item">
        GTE (Generalized Tuning Embeddings)
      </Menu.Item>
      <Menu.Item key="Instructor-XL" className="dropdown-menu-item">
        Instructor-XL (Instruction-tuned embeddings)
      </Menu.Item>
      <Menu.Item key="LLaMA Embeddings" className="dropdown-menu-item">
        LLaMA Embeddings
      </Menu.Item>
      <Menu.Item key="E5 Embeddings" className="dropdown-menu-item">
        E5 Embeddings
      </Menu.Item>
    </Menu>
  );

  const indexTypeMenu = (
    <Menu onClick={({ key }) => handleIndexTypeChange(key)}>
      <Menu.Item key="Flat" className="dropdown-menu-item">
        Flat
      </Menu.Item>
      <Menu.Item key="HNSW" className="dropdown-menu-item">
        HNSW
      </Menu.Item>
    </Menu>
  );

  useEffect(() => {
    const nodeCount = dbDag.filter((el) => el.node === "vector_db").length;
    const name = `vector_db_${nodeCount + 1}`;
    const val = {
      id: fid,
      name: name,
      description: `vectordb_${nodeCount + 1}`,
      node: "vector_db",
      properties: {
        db: vectorDatabase,
        embedding_model: embeddingModel,
        index_type: indexType,
      },
    };
    setdbDag([...dbDag, val]);
    setDbName(name);
    setDbNameField(name);
  }, []);

  const updateProperties = (props) => {
    const node = dbDag.find((el) => el.name === dbName);
    const p = node?.properties ? node.properties : {};
    const val = {
      name: dbName,
      properties: { ...node, ...props },
    };
    setdbDag(dbDag.filter((el) => el.name !== dbName).concat(val));
  };

  return (
    <Card
      title={
        <div className="card-header">
          <LuBrainCircuit className="icon" />
          <span className="header-text">Vector Database Creation</span>
          <div
            onClick={handleRun}
            className="run-button"
            style={{ marginLeft: "auto", cursor: "pointer" }}
          >
            {loading ? (
              <FiLoader className="loader" spin />
            ) : (
              <FiPlay className="play" />
            )}
          </div>
        </div>
      }
      bordered={false}
      className="config-card"
      style={{ height: "auto" }}
    >
      <Tabs defaultActiveKey="1" className="config-tabs">
        {/* Vector Database Tab */}
        <TabPane tab="Vector Database" key="1">
          <div className="input-section">
            <div className="option-label">Vector Database</div>
            <Dropdown overlay={vectorDatabaseMenu} trigger={["click"]}>
              <div className="dropdown-button">
                <span>{vectorDatabase}</span>
                <FiChevronDown className="dropdown-icon" />
              </div>
            </Dropdown>
            <Text className="help-text">
              Select the vector database for storing embeddings.
            </Text>
          </div>

          <div className="input-section">
            <div className="option-label">Embedding Model</div>
            <Dropdown overlay={embeddingModelMenu} trigger={["click"]}>
              <div className="dropdown-button">
                <span>{embeddingModel}</span>
                <FiChevronDown className="dropdown-icon" />
              </div>
            </Dropdown>
            <Text className="help-text">
              Select the embedding model for vector creation and retrieval.
            </Text>
          </div>
          <div className="input-section">
            <div className="option-label">Index Type</div>
            <Dropdown overlay={indexTypeMenu} trigger={["click"]}>
              <div className="dropdown-button">
                <span>{indexType}</span>
                <FiChevronDown className="dropdown-icon" />
              </div>
            </Dropdown>
            <Text className="help-text">
              Select index type (Flat for small datasets, HNSW for large-scale
              datasets).
            </Text>
          </div>
        </TabPane>
      </Tabs>

      <div className="input-section">
        <div className="option-label">Db name</div>
        <Input
          className="input-field"
          onChange={handleNameChange}
          value={dbNameField}
        />
      </div>

      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Button
          style={{ marginTop: "50px" }}
          type="primary"
          onClick={async () => {
            if (!dbName) {
              notification.error({
                message: "Db name cannot be empty",
                description: "Please provide a name for this database.",
              });
              return;
            }

            if (fid === "") {
              notification.error({
                message: "Flow name must not be empty",
                description: "You must save a flow before creating a database.",
              });
              return;
            }

            const data = { dag: dbDag, uid: userId, flow_id: fid };
            notification.info({
              message: "Loading...",
              description: "Database is being created",
              key: "loading",
              duration: 0,
              icon: (
                <span className="m-2">
                  <Loader isMini={true} />
                </span>
              ),
            });

            try {
              await dataApi.post("/generate_flow", data, {
                params: { uid: userId, flow_id: fid },
                headers: { "Content-Type": "application/json" },
              });
              notification.destroy();
              notification.success({
                message: "Success",
                description: "Databse creation successful.",
              });

              if (!dbs.includes(dbName)) setDbs([...dbs, dbName]);
            } catch (e) {
              notification.destroy();
              notification.error({
                message: "Error",
                description: "Flow creation failed",
              });
              console.log(e);
            }
          }}
        >
          Create Database
        </Button>
      </div>
    </Card>
  );
};

const VectorDatabaseNode = () => {
  return (
    <div style={{ borderRadius: "5px", padding: "10px" }}>
      <Handle
        type="target"
        position={Position.Left}
        style={{
          background: "transparent",
          border: "2px solid #FFD700",
          borderRadius: "50%",
          width: "10px",
          height: "10px",
        }}
      />
      <Handle
        type="source"
        position={Position.Right}
        style={{
          background: "transparent",
          border: "2px solid #FFD700",
          borderRadius: "50%",
          width: "10px",
          height: "10px",
        }}
      />
      <VectorDatabaseConfig />
    </div>
  );
};

export default VectorDatabaseNode;
