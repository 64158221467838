import PersistentDrawerRight from "../components/rightSide";

function Canvas() {
  return (
    <>
      <PersistentDrawerRight></PersistentDrawerRight>
    </>
  );
}

export default Canvas;
