export const nodeTitleMap = new Map([
  ["Upload Data", "uploadCard"],
  ["Chunking Data", "chunkingData"],
  ["Big LLMs", "bigModal"],
  ["Knowledge Retrieving", "knowledgeRetrieving"],
  ["Vector Database Creation", "vectorDatabaseCreation"],
  ["Hypothetical Query Generation", "hypotheticalQueryGeneration"],
  ["Knowledge Graph Construction", "knowledgeGraphConstruction"],
  ["Realtime Analysis", "realtimeAnalysis"],
  ["Query Normalization", "queryNormalization"],
  ["Answer Refinement", "answerRefinement"],
  ["Post Generation Hallucination Shield", "postGenerationHallucinationShield"],
  ["Data Preprocessing", "dataPreprocessing"],
  ["Reranker", "reranker"],
  ["Error Handling", "errorHandling"],
  ["Hallucination Shield", "hallucinationShield"],
  ["Hybrid Retrieval Configuration", "hybridRetrievalConfig"],
  ["Select Database", "selectDatabase"],
  ["Test Flow", "testFlow"],
]);
