import React, { useEffect, useState } from "react";
import { styled, alpha } from "@mui/material/styles";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import DeleteIcon from "@mui/icons-material/Delete";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { dataApi } from "../../services/apiHelpers";
import { useAtom, useAtomValue } from "jotai";
import { flowId, uid } from "../../state/state";
import { notification } from "antd";
import { getFlowIdFromName } from "../../helpers/flows";

const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color: "rgb(55, 65, 81)",
    boxShadow:
      "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
    "& .MuiMenu-list": {
      padding: "4px 0",
    },
    "& .MuiMenuItem-root": {
      "& .MuiSvgIcon-root": {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      "&:active": {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity,
        ),
      },
    },
    ...theme.applyStyles("dark", {
      color: theme.palette.grey[300],
    }),
  },
}));

const RestoreMenu = ({ handleRestore, flowType, setTextFieldValue }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [flows, setFlows] = useState({});
  const [filteredFlows, setFilteredFlows] = useState([]);
  const [fid, setFid] = useAtom(flowId);
  const userId = useAtomValue(uid);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    getFlows();
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const getFlows = async () => {
    try {
      const res = await dataApi.post("/get_flow", {
        user_id: userId,
        flow_id: "",
      });
      const flows = res.data;
      setFlows(flows);
      const entries = Object.entries(flows);
      const usableFlows = entries
        .filter((el) => el[1].flow_object.flowType === flowType)
        .map((x) => x[1].flow_name);
      setFilteredFlows(usableFlows);
    } catch (e) {
      notification.error({
        message: "Could not fetch existing flows.",
        description: "Something went wrong.",
      });
    }
  };

  const handleDeleteFlow = async (flowName) => {
    const flowId = await getFlowIdFromName(flowName);
    try {
      await dataApi.post("/delete_object", {
        user_id: userId,
        flow_id: flowId,
      });
      setFilteredFlows((prev) => prev.filter((flow) => flow !== flowName));
      notification.success({
        message: "Flow deleted",
        description: `${flowName} has been successfully deleted.`,
      });
    } catch (error) {
      notification.error({
        message: "Delete failed",
        description: `Could not delete ${flowName}. Please try again.`,
      });
    }
  };

  return (
    <div>
      <Button
        id="demo-customized-button"
        aria-controls={open ? "demo-customized-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        variant="contained"
        disableElevation
        onClick={handleClick}
        endIcon={<KeyboardArrowDownIcon />}
        color="secondary"
        style={{ width: "100%", height: "100%" }}
      >
        Restore
      </Button>
      <StyledMenu
        id="demo-customized-menu"
        MenuListProps={{
          "aria-labelledby": "demo-customized-button",
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        {filteredFlows.map((el) => (
          <MenuItem key={el} disableRipple>
            <div className="flex justify-between items-center w-full">
              <span
                onClick={async () => {
                  handleClose();
                  await handleRestore(el, flows, setTextFieldValue, setFid);
                }}
              >
                {el}
              </span>
              <DeleteIcon
                className="text-red-600 cursor-pointer"
                onClick={() => handleDeleteFlow(el)}
              />
            </div>
          </MenuItem>
        ))}
      </StyledMenu>
    </div>
  );
};

export default RestoreMenu;
