import React, { useCallback, useEffect, useRef, useState } from "react";
import { Button, TextField } from "@mui/material";
import SaveAltIcon from "@mui/icons-material/SaveAlt";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import ReactFlow, { Handle, Position } from "react-flow-renderer";
import stepsData from "./categories"; // Import your stepsData here
import UploadCard from "./uploadCard"; // Adjust the import path as needed
import ModelConfigCard from "./bigModal"; // Adjust the import path as needed
import TwoCards from "./twoCards";
import HybridRetrievalConfigNode from "./HybridRetrievalConfig";
import DataPreprocessingCard from "./DataPreprocessingCard";
import RerankerModelStepNode from "./ReRankerModel";
import ErrorHandlingConfigNode from "./errorhandling";
import "./paper.css";
import HallucinationShieldConfigNode from "./HallucinationShieldConfigNode";
import QueryNormalizationConfigNode from "./QueryNormalizationConfig";
import AnswerRefinementConfigNode from "./AnswerRefinementConfig";
import PostGenerationHallucinationShieldConfigNode from "./PostGenerationHallucinationShieldConfig";
import RealTimeAnalyticsConfigNode from "./RealTimeAnalyticsConfig";
import KnowledgeGraphConfigNode from "./KnowledgeGraphConfig";
import HypotheticalQueryGenerationNode from "./HypotheticalQueryGenerationConfig ";
import ChunkingDataNode from "./ChunkingDataConfig ";
import VectorDatabaseNode from "./VectorDatabaseConfig";
import KnowledgeRetrievalNode from "./KnowledgeRetrievalConfig ";
import SelectDatabaseNode from "./SelectDatabaseConfig";
import TestFlowNode from "./TestFlowConfig";
import RestoreMenu from "./common/RestoreMenu";
import { useAtom } from "jotai";
import {
  dbCreationDag,
  flowId,
  flowName,
  savedCreationFlows,
} from "../state/state";
import {
  handleNodeClick,
  handleRestore,
  onDragOver,
  onDropNode,
  saveFlow,
} from "../helpers/flows";

// Custom node component for UploadCard
const DataPreprocessingCardNode = () => {
  return (
    <div style={{ backgroundColor: "#333", borderRadius: "5px" }}>
      <Handle
        type="target"
        position={Position.Left}
        style={{
          background: "transparent",
          border: "2px solid #FFD700",
          borderRadius: "50%",
          width: "10px",
          height: "10px",
        }}
      />
      <Handle
        type="source"
        position={Position.Right}
        style={{
          background: "transparent",
          border: "2px solid #FFD700",
          borderRadius: "50%",
          width: "10px",
          height: "10px",
        }}
      />
      <DataPreprocessingCard />
    </div>
  );
};

const UploadCardNode = () => {
  return (
    <div style={{ backgroundColor: "#333", borderRadius: "5px" }}>
      <Handle
        type="target"
        position={Position.Left}
        style={{
          background: "transparent",
          border: "2px solid #FFD700",
          borderRadius: "50%",
          width: "10px",
          height: "10px",
        }}
      />
      <Handle
        type="source"
        position={Position.Right}
        style={{
          background: "transparent",
          border: "2px solid #FFD700",
          borderRadius: "50%",
          width: "10px",
          height: "10px",
        }}
      />
      <UploadCard />
    </div>
  );
};

// Custom node component for ModelConfigCard
const BigModalNode = () => {
  return (
    <div style={{ backgroundColor: "#333", borderRadius: "5px" }}>
      <Handle
        type="target"
        position={Position.Left}
        style={{
          background: "transparent",
          border: "2px solid #FFD700",
          borderRadius: "50%",
          width: "10px",
          height: "10px",
        }}
      />
      <Handle
        type="source"
        position={Position.Right}
        style={{
          background: "transparent",
          border: "2px solid #FFD700",
          borderRadius: "50%",
          width: "10px",
          height: "10px",
        }}
      />
      <ModelConfigCard />
    </div>
  );
};

// Node types that include our custom nodes
const nodeTypes = {
  uploadCard: UploadCardNode,
  bigModal: BigModalNode,
  dataPreprocessing: DataPreprocessingCardNode,
  hybridRetrievalConfig: HybridRetrievalConfigNode,
  reranker: RerankerModelStepNode,
  errorHandling: ErrorHandlingConfigNode,
  hallucinationShield: HallucinationShieldConfigNode,
  queryNormalization: QueryNormalizationConfigNode,
  answerRefinement: AnswerRefinementConfigNode,
  postGenerationHallucinationShield:
    PostGenerationHallucinationShieldConfigNode,
  realtimeAnalysis: RealTimeAnalyticsConfigNode,
  knowledgeGraphConstruction: KnowledgeGraphConfigNode,
  hypotheticalQueryGeneration: HypotheticalQueryGenerationNode,
  chunkingData: ChunkingDataNode,
  vectorDatabaseCreation: VectorDatabaseNode,
  knowledgeRetrieving: KnowledgeRetrievalNode,
  selectDatabase: SelectDatabaseNode,
  testFlow: TestFlowNode,
};

const CreationComponent = ({
  nodes,
  edges,
  onNodesChange,
  onEdgesChange,
  onConnect,
  setNodes,
  setEdges,
}) => {
  const [_selectedStep, setSelectedStep] = useState(null);
  const containerRef = useRef(null);
  const [_isLoginPopupOpen, setLoginPopupOpen] = useState(false);
  const [rfInstance, setRfInstance] = useState(null);
  const [textFieldValue, setTextFieldValue] = useState("");
  const [flows, setFlows] = useAtom(savedCreationFlows);
  const [activeFlow, setActiveFlow] = useAtom(flowName);
  const [dag, setDag] = useAtom(dbCreationDag);
  const [fid, setFid] = useAtom(flowId);

  const clearNodes = () => {
    setNodes([]);
    setEdges([]);
    setDag([]);
  };

  useEffect(() => {
    setLoginPopupOpen(true);
  }, []);

  const onDrop = useCallback(
    onDropNode(containerRef, stepsData, nodes, setNodes),
    [nodes],
  );

  return (
    <div style={{ height: "84vh", display: "flex", flexDirection: "column" }}>
      <div
        style={{
          display: "grid",
          width: "100%",
          gridTemplateColumns: "1fr 1fr 6fr 1fr 1fr",
          padding: "0 0 0 10px",
        }}
      >
        <TextField
          id="filled-basic"
          label="Flow Name"
          variant="filled"
          value={textFieldValue}
          onChange={(e) => setTextFieldValue(e.currentTarget.value)}
        />
        <Button
          variant="contained"
          onClick={saveFlow(
            textFieldValue,
            setTextFieldValue,
            flows,
            setFlows,
            rfInstance,
            setActiveFlow,
            "creation",
          )}
        >
          <SaveAltIcon /> Save Flow
        </Button>
        <div />
        <Button variant="contained" onClick={clearNodes}>
          <DeleteOutlineIcon />
          Clear Nodes
        </Button>
        <RestoreMenu
          handleRestore={handleRestore(
            setNodes,
            setEdges,
            setActiveFlow,
            setFid,
          )}
          setTextFieldValue={setTextFieldValue}
          flowType="creation"
        />
      </div>
      <div
        ref={containerRef}
        onDrop={onDrop}
        onDragOver={onDragOver}
        style={{ flex: 1 }}
      >
        <ReactFlow
          nodes={nodes}
          edges={edges}
          onNodesChange={onNodesChange}
          onEdgesChange={onEdgesChange}
          onConnect={onConnect}
          onNodeClick={handleNodeClick(stepsData, setSelectedStep)}
          onInit={setRfInstance}
          nodeTypes={nodeTypes} // Include the custom node types
          fitView
          style={{
            width: "100%",
            height: "100%", // Set ReactFlow to take 100% height
            backgroundColor: "#252539",
            borderRadius: "10px",
          }}
        />
      </div>
      <TwoCards></TwoCards>
    </div>
  );
};

export default CreationComponent;
