import React, { useEffect } from "react";
import { Card, Dropdown, Menu, Typography, Button, notification } from "antd";
import { FiChevronDown } from "react-icons/fi";
import { GoDatabase } from "react-icons/go";
import { Handle, Position, useReactFlow } from "react-flow-renderer";
import { useAtom, useAtomValue } from "jotai";
import { dataApi } from "../services/apiHelpers";
import { availableDbs, selectedDatabaseAtom, uid } from "../state/state";
import AddCard from "./AddCard/AddCard";
import stepsCategories from "./categories";
import { nodeTitleMap } from "../constants/nodeTitles";
const { Text } = Typography;

const SelectDatabaseConfig = () => {
  const [selectedDatabase, setSelectedDatabase] = useAtom(selectedDatabaseAtom);
  const [availableDatabases, setAvailableDatabases] = useAtom(availableDbs);
  const userId = useAtomValue(uid);

  const populateDbList = async () => {
    try {
      const res = await dataApi.post("/list_db", { uid: userId });
      const dbs = Object.values(res.data).map((db) => ({
        db_id: db.db_id,
        db_name: db.db_name || db.db_id,
      }));
      setAvailableDatabases(dbs);
    } catch (e) {
      notification.error({
        message: "Could not fetch database list",
        description: "Something went wrong",
      });
    }
  };

  useEffect(() => {
    populateDbList();
  }, []);

  const handleDatabaseChange = ({ key }) => {
    // Find and set the selected database object directly
    const selectedDb = availableDatabases.find((db) => db.db_id === key);
    setSelectedDatabase(selectedDb);
  };

  const dropdownMenu = (
    <Menu onClick={handleDatabaseChange}>
      {availableDatabases.map(({ db_id, db_name }) => (
        <Menu.Item key={db_id} className="dropdown-menu-item">
          {db_name}
        </Menu.Item>
      ))}
    </Menu>
  );

  const handleSelectDatabase = () => {
    if (selectedDatabase) {
      notification.success({
        message: "Database Selected",
        description: `You have selected the ${selectedDatabase.db_name} database.`,
      });
    } else {
      notification.error({
        message: "No Database Selected",
        description: "Please select a database before proceeding.",
      });
    }
  };

  return (
    <Card
      title={
        <div className="card-header">
          <GoDatabase className="icon" />
          <span className="header-text">Select Database</span>
        </div>
      }
      bordered={false}
      className="upload-card"
    >
      <div style={{ display: "flex", flexDirection: "column", gap: "10px" }}>
        <div className="input-section">
          <div className="option-label">Database</div>
          <Dropdown overlay={dropdownMenu} trigger={["click"]}>
            <div className="dropdown-button">
              {/* Show the db_name if selected, or prompt to select a database */}
              <span>{selectedDatabase?.db_name || "Select a database"}</span>
              <FiChevronDown className="dropdown-icon" />
            </div>
          </Dropdown>
          <Text className="help-text">
            Select a database from the predefined options.
          </Text>
        </div>

        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Button
            style={{ marginTop: "20px" }}
            type="primary"
            onClick={handleSelectDatabase}
          >
            Select Database
          </Button>
        </div>
      </div>
    </Card>
  );
};

const SelectDatabaseNode = () => {
  const { addNodes } = useReactFlow();

  const nextNodeOptions = stepsCategories
    .find((category) => category.category === "Knowledge")
    ?.steps.filter(
      (step) =>
        step.name === "Knowledge Retrieving" ||
        step.name === "Hybrid Retrieval Configuration"
    );

  const onAddNode = (nodeData) => {
    const nodeType = nodeTitleMap.get(nodeData.name) || "default";
    const newNode = {
      id: `${nodeData.id}-${Date.now()}`,
      type: nodeType,
      position: { x: 250, y: 150 },
      data: { label: nodeData.name },
    };
    addNodes(newNode);
  };

  return (
    <div className="relative bg-[#333] rounded-[5px]">
      <div className="absolute top-2 -right-[2.5rem]">
        <AddCard newCardOptions={nextNodeOptions} onAddNode={onAddNode} />
      </div>

      <Handle
        type="target"
        position={Position.Left}
        style={{
          background: "transparent",
          border: "2px solid #FFD700",
          borderRadius: "50%",
          width: "10px",
          height: "10px",
        }}
      />
      <Handle
        type="source"
        position={Position.Right}
        style={{
          background: "transparent",
          border: "2px solid #FFD700",
          borderRadius: "50%",
          width: "10px",
          height: "10px",
        }}
      />
      <SelectDatabaseConfig />
    </div>
  );
};

export default SelectDatabaseNode;
