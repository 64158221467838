import React from "react";
import { Button as AntBtn } from "antd";
import "./Button.css";

const Button = ({ placeholder, type, onClick, disabled, icon }) => {
  const customStyle =
    type === "primary"
      ? {
          backgroundColor: "#43413A",
          borderColor: "#005A2D",
          color: "#fff",
        }
      : {};

  return (
    <AntBtn
      type={type}
      onClick={onClick}
      disabled={disabled}
      style={customStyle}
      icon={icon}
      className={type === "primary" ? "custom-primary-btn" : ""}
    >
      {placeholder}
    </AntBtn>
  );
};

export default Button;
