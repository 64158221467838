import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import "./App.css";

import Canvas from "./pages/Canvas";
import ApiDocs from "./pages/ApiDocs";

function App() {
  return (
    <>
      <Router>
        <Routes>
          <Route path="/" element={<Canvas />} />
          <Route path="/documentation" element={<ApiDocs />} />
        </Routes>
      </Router>
    </>
  );
}

export default App;
