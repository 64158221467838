import React from "react";
import { useNavigate } from "react-router-dom";
import { createTheme, ThemeProvider, styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import CssBaseline from "@mui/material/CssBaseline";
import Typography from "@mui/material/Typography";

import { CodeSandboxOutlined, ProfileOutlined } from "@ant-design/icons";

import Button from "../ui/button";

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme }) => ({
  transition: theme.transitions.create(["margin", "width"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  backgroundColor: "#252539",
}));

export default function SdkHeader() {
  const navigate = useNavigate();
  const customTheme = createTheme({
    typography: {
      fontFamily: "Roboto, Arial, sans-serif",
      h6: {
        fontSize: "0.875rem",
      },
      body2: {
        fontSize: "0.75rem",
      },
    },
  });
  return (
    <ThemeProvider theme={customTheme}>
      <Box sx={{ display: "flex" }}>
        <CssBaseline />
        {/* AppBar */}
        <AppBar position="fixed">
          <Toolbar>
            <img
              src="/Images/ia-logo-icon.png"
              alt="ia"
              width="40"
              height="40"
              onClick={() => navigate("/")}
              className="cursor-pointer mr-2"
            />
            <Typography
              variant="h6"
              noWrap
              sx={{ flexGrow: 1 }}
              component="div"
            >
              Inference Analytics AI
            </Typography>
            {/* Page Toggle Button */}
            <Box sx={{ display: "flex", marginRight: "0" }}>
              <Button
                icon={<CodeSandboxOutlined />}
                type="default"
                placeholder="Studio"
                onClick={() => navigate("/")}
              />
            </Box>
          </Toolbar>
        </AppBar>
      </Box>
    </ThemeProvider>
  );
}
