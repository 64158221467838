import React, { useState } from 'react';
import { Card, Input, Dropdown, Menu, Typography, Tabs, notification } from 'antd';
import { FiChevronDown, FiPlay, FiLoader } from 'react-icons/fi';
import { LuBrainCircuit } from 'react-icons/lu';
import { Handle, Position } from 'react-flow-renderer';

const { Text } = Typography;
const { TabPane } = Tabs;

const HypotheticalQueryGenerationConfig = () => {
  const [llmModel, setLlmModel] = useState('GPT 4o mini');
  const [hypoQueryPrompt, setHypoQueryPrompt] = useState(
    'Default hypo query generation prompt for GPT 4o mini and Llama 8B (based on user selection)'
  );
  const [loading, setLoading] = useState(false);

  const handleLlmModelChange = (value) => {
    setLlmModel(value);
  };

  const llmMenu = (
    <Menu onClick={({ key }) => handleLlmModelChange(key)}>
      <Menu.Item key="GPT 4o mini" className="dropdown-menu-item">GPT 4o mini</Menu.Item>
      <Menu.Item key="Llama 8B" className="dropdown-menu-item">Llama 8B</Menu.Item>
    </Menu>
  );

  const handleRun = () => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
      notification.success({
        message: 'Query Generated',
        description: `The hypothetical query has been generated using ${llmModel}.`,
      });
    }, 2000);
  };

  return (
    <Card
      title={
        <div className="card-header">
          <LuBrainCircuit className="icon" />
          <span className="header-text">Hypothetical Query Generation</span>
          <div onClick={handleRun} className="run-button" style={{ cursor: 'pointer' }}>
            {loading ? (
              <FiLoader className="loader" spin />
            ) : (
              <FiPlay className="play" />
            )}
          </div>
        </div>
      }
      bordered={false}
      className="config-card"
    >
      <Tabs defaultActiveKey="1" className="config-tabs">
        <TabPane tab="Query Generation" key="1">
          <div className="input-section">
            <div className="option-label">Prompt</div>
            <Input
              value={hypoQueryPrompt}
              onChange={(e) => setHypoQueryPrompt(e.target.value)}
              className="input-field"
            />
            <Text className="help-text">
              Enter a custom prompt for generating hypothetical queries, or use the default one.
            </Text>
          </div>

          <div className="input-section">
            <div className="option-label">LLM Model</div>
            <Dropdown overlay={llmMenu} trigger={['click']}>
              <div className="dropdown-button">
                <span>{llmModel}</span>
                <FiChevronDown className="dropdown-icon" />
              </div>
            </Dropdown>
            <Text className="help-text">
              Select the LLM model for generating hypothetical queries.
            </Text>
          </div>
        </TabPane>
      </Tabs>
    </Card>
  );
};

const HypotheticalQueryGenerationNode = () => {
  return (
    <div style={{ borderRadius: '5px', padding: '10px' }}>
      <Handle
        type="target"
        position={Position.Left}
        style={{
          background: 'transparent',
          border: '2px solid #FFD700',
          borderRadius: '50%',
          width: '10px',
          height: '10px',
        }}
      />
      <Handle
        type="source"
        position={Position.Right}
        style={{
          background: 'transparent',
          border: '2px solid #FFD700',
          borderRadius: '50%',
          width: '10px',
          height: '10px',
        }}
      />
      <HypotheticalQueryGenerationConfig />
    </div>
  );
};

export default HypotheticalQueryGenerationNode;
