import React, { useState, useEffect } from "react";
import { Card, notification, Dropdown, Menu } from "antd";
import { FiUpload, FiPlay, FiLoader, FiChevronDown } from "react-icons/fi";
import DeleteIcon from "@mui/icons-material/Delete";
import Dropzone from "react-dropzone";
import { dataApi } from "../services/apiHelpers";
import { useAtom } from "jotai";
import { uid, flowId, fileNamesAtom, flowName } from "../state/state";

const UploadCard = () => {
  const [selectedOption, setSelectedOption] = useState("Documents");
  const [selectedFormat, setSelectedFormat] = useState("PDF");
  const [loading, setLoading] = useState(false);
  const [fileNames, setFileNames] = useAtom(fileNamesAtom);
  const [uploadedFile, setUploadedFile] = useState(null);
  const [userId] = useAtom(uid);
  const [fid] = useAtom(flowId);
  const [flow] = useAtom(flowName);

  const handleMenuClick = (e) => {
    setSelectedOption(e.key);
    setSelectedFormat(""); // Reset format selection when changing the option
  };

  const handleFormatClick = (format) => {
    setSelectedFormat(format);
  };

  const getFileNames = async () => {
    const res = await dataApi.post("/list_files", {
      user_id: userId,
      flow_id: fid,
    });
    return res.data;
  };

  const fileNamesSet = async () => {
    const files = await getFileNames();
    setFileNames(files);
  };

  const uploadFile = async (file) => {
    const formData = new FormData();
    formData.append("files", file);
    try {
      await dataApi.post("/upload_file", formData, {
        params: { uid: userId, flow_id: fid },
        headers: { "Content-Type": "multipart/form-data" },
      });
      notification.success({
        message: "Upload Successful",
        description: "Your file has been uploaded successfully.",
      });
      fileNamesSet();
    } catch (e) {
      console.log(e);
    }
  };

  const handleUpload = async (file) => {
    setLoading(true);
    uploadFile(file);
  };

  const getParameters = () => {
    switch (selectedOption) {
      case "Documents":
        return ["PDF", "Word Documents (DOCX)", "Text Files (TXT)"];
      case "Datasets":
        return ["CSV", "Excel Files (XLSX)", "JSON"];
      case "Structured Data":
        return ["Database Export (SQL, NoSQL)", "XML", "RDF/Turtle"];
      default:
        return [];
    }
  };

  const handleDeleteFile = async (fileName) => {
    try {
      await dataApi.post("/delete_object", {
        user_id: userId,
        flow_id: fid,
        file_name: fileName,
      });
      setFileNames(fileNames.filter((name) => name !== fileName));
      notification.success({
        message: "File Deleted",
        description: `The file "${fileName}" has been deleted.`,
      });
    } catch (e) {
      console.error("Error deleting file:", e);
      notification.error({
        message: "Deletion Error",
        description: "Unable to delete the file. Please try again.",
      });
    }
  };

  const dropdownMenu = (
    <Menu onClick={handleMenuClick}>
      <Menu.Item key="Documents" className="dropdown-menu-item">
        Documents
      </Menu.Item>
      <Menu.Item key="Datasets" className="dropdown-menu-item">
        Datasets
      </Menu.Item>
      <Menu.Item key="Structured Data" className="dropdown-menu-item">
        Structured Data
      </Menu.Item>
    </Menu>
  );

  const formatMenu = (
    <Menu>
      {getParameters().map((format, index) => (
        <Menu.Item
          key={index}
          onClick={() => handleFormatClick(format)}
          className="dropdown-menu-item"
        >
          {format}
        </Menu.Item>
      ))}
    </Menu>
  );

  useEffect(() => {
    const initialFormat = getParameters()[0];
    if (initialFormat) {
      setSelectedFormat(initialFormat);
    }
  }, [selectedOption]);

  useEffect(() => {
    if (flow !== "") fileNamesSet();
  }, [fid, flow]);

  return (
    <Card
      title={
        <div className="upload-card-title">
          <div className="upload-card-header">
            <FiUpload className="upload-icon" />
            <span className="upload-card-header-text">Upload Data</span>
          </div>
        </div>
      }
      bordered={false}
      className="upload-card"
    >
      <div className="upload-card-content">
        <div className="option-label">Select Option:</div>
        <Dropdown overlay={dropdownMenu} trigger={["click"]}>
          <div className="dropdown-button">
            <span>{selectedOption}</span>
            <FiChevronDown className="dropdown-icon" />
          </div>
        </Dropdown>

        <div className="option-label">Select Format:</div>
        <Dropdown overlay={formatMenu} trigger={["click"]}>
          <div className="dropdown-button">
            <span>{selectedFormat || "Select Format"}</span>
            <FiChevronDown className="dropdown-icon" />
          </div>
        </Dropdown>
        <br />
        <Dropzone
          onDrop={(acceptedFiles) => {
            const [file] = acceptedFiles;
            handleUpload(file);
          }}
        >
          {({ getRootProps, getInputProps }) => (
            <section className="dropzone" {...getRootProps()}>
              <input {...getInputProps()} />
              <p>Drag 'n' drop file here, or click to select files</p>
            </section>
          )}
        </Dropzone>
        {fileNames.length > 0 && (
          <div className="max-h-20 overflow-auto mt-5 gap-3 border-dashed border-2 border-white">
            {fileNames.map((el) => (
              <div key={el} className="flex justify-between items-center p-2">
                <span>{el}</span>
                <DeleteIcon
                  className="cursor-pointer text-red-500 hover:text-red-700"
                  onClick={() => handleDeleteFile(el)}
                />
              </div>
            ))}
          </div>
        )}
      </div>
    </Card>
  );
};

export default UploadCard;
