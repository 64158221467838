import { atom, createStore } from "jotai";

export const dbCreationDag = atom([]);
export const flowDag = atom([]);
export const flowName = atom("");
export const flowId = atom(crypto.randomUUID());
export const uid = atom("user2");
export const availableDbs = atom([]);
export const fileNamesAtom = atom([]);
export const savedCreationFlows = atom(new Map());
export const savedGenerationFlows = atom(new Map());

// Generation flow basic
export const predictionDag = atom([]);
export const selectedDatabaseAtom = atom(null);
export const knowledgeRetrievalConfigAtom = atom({});
export const bigModelConfigAtom = atom({});
export const store = createStore();
