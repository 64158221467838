// KnowledgeRetrievalConfig.js
import React, { useState, useEffect } from "react";
import {
  Card,
  Dropdown,
  Menu,
  Typography,
  Tabs,
  InputNumber,
  notification,
} from "antd";
import { FiChevronDown, FiPlay, FiLoader } from "react-icons/fi";
import { LuBrainCircuit } from "react-icons/lu";
import { Handle, Position } from "react-flow-renderer";
import { useAtom } from "jotai";
import { knowledgeRetrievalConfigAtom } from "../state/state";

const { Text } = Typography;
const { TabPane } = Tabs;

const KnowledgeRetrievalConfig = () => {
  const [searchType, setSearchType] = useState("similarity");
  const [similarityScoreThreshold, setSimilarityScoreThreshold] = useState(0.7);
  const [topKStandard, setTopKStandard] = useState(10);
  const [topKMultiQuery, setTopKMultiQuery] = useState(10);
  const [topKSmartCompression, setTopKSmartCompression] = useState(10);
  const [topKLongDoc, setTopKLongDoc] = useState(10);
  const [distanceMetric, setDistanceMetric] = useState("cosine");
  const [loading, setLoading] = useState(false);

  const [activeTabKey, setActiveTabKey] = useState("1");

  const [, setKnowledgeRetrievalConfig] = useAtom(knowledgeRetrievalConfigAtom);

  useEffect(() => {
    const config = {
      searchType,
      similarityScoreThreshold,
      topKStandard,
      topKMultiQuery,
      topKSmartCompression,
      topKLongDoc,
      distanceMetric,
      activeTabKey,
    };
    setKnowledgeRetrievalConfig(config);
  }, [
    searchType,
    similarityScoreThreshold,
    topKStandard,
    topKMultiQuery,
    topKSmartCompression,
    topKLongDoc,
    distanceMetric,
    activeTabKey,
    setKnowledgeRetrievalConfig,
  ]);

  const handleSearchTypeChange = (key) => {
    setSearchType(key);
  };

  const handleDistanceMetricChange = (key) => {
    setDistanceMetric(key);
  };

  const searchTypeMenu = (
    <Menu onClick={({ key }) => handleSearchTypeChange(key)}>
      <Menu.Item key="similarity" className="dropdown-menu-item">
        Similarity
      </Menu.Item>
      <Menu.Item key="mmr" className="dropdown-menu-item">
        MMR
      </Menu.Item>
    </Menu>
  );

  const distanceMetricMenu = (
    <Menu onClick={({ key }) => handleDistanceMetricChange(key)}>
      <Menu.Item key="cosine" className="dropdown-menu-item">
        Cosine
      </Menu.Item>
      <Menu.Item key="dot_product" className="dropdown-menu-item">
        Dot Product
      </Menu.Item>
      <Menu.Item key="euclidean" className="dropdown-menu-item">
        Euclidean
      </Menu.Item>
    </Menu>
  );

  const handleRun = () => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
      notification.success({
        message: "Knowledge Retrieval Configuration Applied",
        description: "Your configuration has been successfully applied.",
      });
    }, 2000);
  };

  const onTabChange = (key) => {
    setActiveTabKey(key);
  };

  return (
    <Card
      title={
        <div className="card-header">
          <LuBrainCircuit className="icon" />
          <span className="header-text">Knowledge Retrieval</span>
          <div onClick={handleRun} className="run-button">
            {loading ? (
              <FiLoader className="loader" spin />
            ) : (
              <FiPlay className="play" />
            )}
          </div>
        </div>
      }
      bordered={false}
      className="config-card"
    >
      <Tabs defaultActiveKey="1" className="config-tabs" onChange={onTabChange}>
        {/* Standard Search */}
        <TabPane tab="Standard" key="1">
          <div className="input-section">
            <div className="option-label">Search Type</div>
            <Dropdown overlay={searchTypeMenu} trigger={["click"]}>
              <div className="dropdown-button">
                <span>{searchType}</span>
                <FiChevronDown className="dropdown-icon" />
              </div>
            </Dropdown>
            <Text className="help-text">
              Select the search type for retrieving results.
            </Text>
          </div>

          <div className="input-section">
            <div className="option-label">Similarity Score Threshold</div>
            <InputNumber
              min={0}
              max={1}
              step={0.01}
              value={similarityScoreThreshold}
              onChange={(value) => setSimilarityScoreThreshold(value)}
              className="input-number"
            />
            <Text className="help-text">
              Set a minimum similarity score for the retrieved results.
            </Text>
          </div>

          <div className="input-section">
            <div className="option-label">Top K Results</div>
            <InputNumber
              min={1}
              value={topKStandard}
              onChange={(value) => setTopKStandard(value)}
              className="input-number"
            />
            <Text className="help-text">
              Number of top results to retrieve.
            </Text>
          </div>

          <div className="input-section">
            <div className="option-label">Distance Metric</div>
            <Dropdown overlay={distanceMetricMenu} trigger={["click"]}>
              <div className="dropdown-button">
                <span>{distanceMetric}</span>
                <FiChevronDown className="dropdown-icon" />
              </div>
            </Dropdown>
            <Text className="help-text">
              Choose the distance metric (Cosine, Dot Product, Euclidean).
            </Text>
          </div>
        </TabPane>

        {/* Multi-Query Search */}
        <TabPane tab="Multi" key="2">
          <div className="input-section">
            <div className="option-label">Search Type</div>
            <Dropdown overlay={searchTypeMenu} trigger={["click"]}>
              <div className="dropdown-button">
                <span>{searchType}</span>
                <FiChevronDown className="dropdown-icon" />
              </div>
            </Dropdown>
            <Text className="help-text">
              Select the search type for multi-query retrieval.
            </Text>
          </div>

          <div className="input-section">
            <div className="option-label">Top K Results</div>
            <InputNumber
              min={1}
              value={topKMultiQuery}
              onChange={(value) => setTopKMultiQuery(value)}
              className="input-number"
            />
            <Text className="help-text">
              Number of top results to retrieve.
            </Text>
          </div>

          <div className="input-section">
            <div className="option-label">Distance Metric</div>
            <Dropdown overlay={distanceMetricMenu} trigger={["click"]}>
              <div className="dropdown-button">
                <span>{distanceMetric}</span>
                <FiChevronDown className="dropdown-icon" />
              </div>
            </Dropdown>
            <Text className="help-text">
              Choose the distance metric for multi-query search.
            </Text>
          </div>
        </TabPane>

        {/* Smart Compression */}
        <TabPane tab="Smart" key="3">
          <div className="input-section">
            <div className="option-label">Search Type</div>
            <Dropdown overlay={searchTypeMenu} trigger={["click"]}>
              <div className="dropdown-button">
                <span>{searchType}</span>
                <FiChevronDown className="dropdown-icon" />
              </div>
            </Dropdown>
            <Text className="help-text">
              Select the search type for smart compression.
            </Text>
          </div>

          <div className="input-section">
            <div className="option-label">Top K Results</div>
            <InputNumber
              min={1}
              value={topKSmartCompression}
              onChange={(value) => setTopKSmartCompression(value)}
              className="input-number"
            />
            <Text className="help-text">
              Number of top results to retrieve for compression.
            </Text>
          </div>

          <div className="input-section">
            <div className="option-label">Distance Metric</div>
            <Dropdown overlay={distanceMetricMenu} trigger={["click"]}>
              <div className="dropdown-button">
                <span>{distanceMetric}</span>
                <FiChevronDown className="dropdown-icon" />
              </div>
            </Dropdown>
            <Text className="help-text">
              Choose the distance metric for smart compression.
            </Text>
          </div>
        </TabPane>

        {/* Long Document Search */}
        <TabPane tab="Long" key="4">
          <div className="input-section">
            <div className="option-label">Search Type</div>
            <Dropdown overlay={searchTypeMenu} trigger={["click"]}>
              <div className="dropdown-button">
                <span>{searchType}</span>
                <FiChevronDown className="dropdown-icon" />
              </div>
            </Dropdown>
            <Text className="help-text">
              Select the search type for long document search.
            </Text>
          </div>

          <div className="input-section">
            <div className="option-label">Top K Results</div>
            <InputNumber
              min={1}
              value={topKLongDoc}
              onChange={(value) => setTopKLongDoc(value)}
              className="input-number"
            />
            <Text className="help-text">
              Number of top results to retrieve for long document search.
            </Text>
          </div>

          <div className="input-section">
            <div className="option-label">Distance Metric</div>
            <Dropdown overlay={distanceMetricMenu} trigger={["click"]}>
              <div className="dropdown-button">
                <span>{distanceMetric}</span>
                <FiChevronDown className="dropdown-icon" />
              </div>
            </Dropdown>
            <Text className="help-text">
              Choose the distance metric for long document search.
            </Text>
          </div>
        </TabPane>
      </Tabs>
    </Card>
  );
};

const KnowledgeRetrievalNode = () => {
  return (
    <div style={{ borderRadius: "5px", padding: "10px" }}>
      <Handle
        type="target"
        position={Position.Left}
        style={{
          background: "transparent",
          border: "2px solid #FFD700",
          borderRadius: "50%",
          width: "10px",
          height: "10px",
        }}
      />
      <Handle
        type="source"
        position={Position.Right}
        style={{
          background: "transparent",
          border: "2px solid #FFD700",
          borderRadius: "50%",
          width: "10px",
          height: "10px",
        }}
      />
      <KnowledgeRetrievalConfig />
    </div>
  );
};

export default KnowledgeRetrievalNode;
