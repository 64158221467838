import axios from "axios";

export const dataApi = axios.create({
  baseURL: "https://data-no-code-rag-prod.ia-chat-assistant.com/",
  headers: {
    "Content-Type": "application/json",
  },
  withCredentials: false,
  timeout: 1000000,
});

export const predictionApi = axios.create({
  baseURL: "https://prediction-no-code-rag-prod.ia-chat-assistant.com/",
  headers: {
    "Content-Type": "application/json",
  },
  withCredentials: false,
  timeout: 1000000,
});
