import React, { useState } from 'react';
import { Card, InputNumber, Dropdown, Menu, Typography, Tabs, notification } from 'antd';
import { FiChevronDown, FiPlay, FiLoader } from 'react-icons/fi';
import { LuBrainCircuit } from 'react-icons/lu';
import { Handle, Position } from 'react-flow-renderer';

const { Text } = Typography;
const { TabPane } = Tabs;

const KnowledgeGraphConfig = () => {
  const [llmModel, setLlmModel] = useState('GPT 4o mini');
  const [embeddingModel, setEmbeddingModel] = useState('text-embedding-ada-002');
  const [retrievalContext, setRetrievalContext] = useState({
    textUnitProp: 0.5,
    communityProp: 0.1,
    maxTurns: 5,
    userTurnsOnly: true,
    topKMappedEntities: 10,
    topKRelationships: 10,
    includeEntityRank: true,
    includeRelationshipWeight: true,
    includeCommunityRank: false,
    maxTokens: 3000
  });
  const [loading, setLoading] = useState(false);

  const handleLlmModelChange = (value) => {
    setLlmModel(value);
  };

  const handleEmbeddingModelChange = (value) => {
    setEmbeddingModel(value);
  };

  const handleRetrievalContextChange = (key, value) => {
    setRetrievalContext((prev) => ({ ...prev, [key]: value }));
  };

  const handleRun = () => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
      notification.success({
        message: 'Knowledge Graph Constructed',
        description: `The knowledge graph has been constructed using ${llmModel} and ${embeddingModel}.`,
      });
    }, 2000);
  };

  const llmMenu = (
    <Menu onClick={({ key }) => handleLlmModelChange(key)}>
      <Menu.Item key="GPT 4o mini" className="dropdown-menu-item">GPT 4o mini</Menu.Item>
      <Menu.Item key="Llama 8B" className="dropdown-menu-item">Llama 8B</Menu.Item>
    </Menu>
  );

  const embeddingMenu = (
    <Menu onClick={({ key }) => handleEmbeddingModelChange(key)}>
      <Menu.Item className="dropdown-menu-item" key="text-embedding-ada-002">OpenAI: text-embedding-ada-002</Menu.Item>
      <Menu.Item className="dropdown-menu-item" key="all-mpnet-base-v2">Sentence Transformers: all-mpnet-base-v2</Menu.Item>
      <Menu.Item className="dropdown-menu-item" key="all-MiniLM-L12-v2">Sentence Transformers: all-MiniLM-L12-v2</Menu.Item>
      <Menu.Item className="dropdown-menu-item" key="Cohere Embeddings">Cohere Embeddings</Menu.Item>
      <Menu.Item className="dropdown-menu-item" key="LLaMA Embeddings">LLaMA Embeddings</Menu.Item>
      <Menu.Item className="dropdown-menu-item" key="E5 Embeddings">E5 Embeddings</Menu.Item>
    </Menu>
  );

  return (
    <Card
      title={
        <div className="card-header">
          <LuBrainCircuit className="icon" />
          <span className="header-text">Knowledge Graph Construction</span>
          <div onClick={handleRun} className="run-button" style={{ cursor: 'pointer', display: 'flex', alignItems: 'center' }}>
            {loading ? (
              <FiLoader className="loader" spin />
            ) : (
              <FiPlay className="play" />
            )}
          </div>
        </div>
      }
      bordered={false}
      className="config-card"
    >
      <Tabs defaultActiveKey="1" className="config-tabs">
        <TabPane tab="Entities & Embeddings" key="1">
          <div className="input-section">
            <div className="option-label">LLM Model</div>
            <Dropdown overlay={llmMenu} trigger={['click']}>
              <div className="dropdown-button">
                <span>{llmModel}</span>
                <FiChevronDown className="dropdown-icon" />
              </div>
            </Dropdown>
            <Text className="help-text">Users can select LLM for entity and relationship extraction.</Text>
          </div>

          <div className="input-section">
            <div className="option-label">Embedding Model</div>
            <Dropdown overlay={embeddingMenu} trigger={['click']}>
              <div className="dropdown-button">
                <span>{embeddingModel}</span>
                <FiChevronDown className="dropdown-icon" />
              </div>
            </Dropdown>
            <Text className="help-text">Users can select embedding model for extracted entities.</Text>
          </div>
        </TabPane>

        <TabPane tab="Retrieval" key="2">
          <div className="input-section">
            <div className="option-label">Text Unit Prop</div>
            <InputNumber
              min={0}
              max={1}
              step={0.01}
              value={retrievalContext.textUnitProp}
              onChange={(value) => handleRetrievalContextChange('textUnitProp', value)}
              className="input-number"
            />
          </div>

          <div className="input-section">
            <div className="option-label">Community Prop</div>
            <InputNumber
              min={0}
              max={1}
              step={0.01}
              value={retrievalContext.communityProp}
              onChange={(value) => handleRetrievalContextChange('communityProp', value)}
              className="input-number"
            />
          </div>

          <div className="input-section">
            <div className="option-label">Max Turns</div>
            <InputNumber
              min={1}
              max={20}
              value={retrievalContext.maxTurns}
              onChange={(value) => handleRetrievalContextChange('maxTurns', value)}
              className="input-number"
            />
          </div>

          <div className="input-section">
            <div className="option-label">Include Entity Rank</div>
            <InputNumber
              value={retrievalContext.includeEntityRank ? 1 : 0}
              onChange={(value) => handleRetrievalContextChange('includeEntityRank', Boolean(value))}
              className="input-number"
            />
          </div>

          {/* Additional parameters can be added here in the same way */}
        </TabPane>
      </Tabs>
    </Card>
  );
};

const KnowledgeGraphConfigNode = () => {
  return (
    <div style={{ borderRadius: '5px', padding: '10px' }}>
      <Handle
        type="target"
        position={Position.Left}
        style={{
          background: 'transparent',
          border: '2px solid #FFD700',
          borderRadius: '50%',
          width: '10px',
          height: '10px',
        }}
      />
      <Handle
        type="source"
        position={Position.Right}
        style={{
          background: 'transparent',
          border: '2px solid #FFD700',
          borderRadius: '50%',
          width: '10px',
          height: '10px',
        }}
      />
      <KnowledgeGraphConfig />
    </div>
  );
};

export default KnowledgeGraphConfigNode;
