import React, { useEffect, useState } from "react";
import { dbCreationDag } from "../state/state";
import {
  Card,
  InputNumber,
  Dropdown,
  Menu,
  Typography,
  Tabs,
  notification,
} from "antd";
import { FiChevronDown, FiPlay, FiLoader } from "react-icons/fi"; // Import the play and loader icons
import { LuBrainCircuit } from "react-icons/lu";
import { Handle, Position } from "react-flow-renderer";
import { useAtom } from "jotai";

const { Text } = Typography;
const { TabPane } = Tabs;

const ChunkingDataConfig = () => {
  const [chunkingMethod, setChunkingMethod] = useState("token-based");
  const [loading, setLoading] = useState(false); // Loading state for the play button
  const [chunkingData, setChunkingData] = useState({
    maxChunkSize: 512,
    maxOverlap: 50,
    separator: ".",
    similarityThreshold: 0.7,
  });
  const [nodeId, setNodeId] = useState("chunking_1");
  const [dbDag, setdbDag] = useAtom(dbCreationDag);

  const handleChunkingMethodChange = (value) => {
    setChunkingMethod(value);
    updateProperties({ chunking_method: value });
  };

  const handleChunkingDataChange = (key, value) => {
    setChunkingData((prev) => ({ ...prev, [key]: value }));
  };

  const handleRun = () => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
      notification.success({
        message: "Chunking Configuration Applied",
        description:
          "Your chunking configuration has been successfully applied.",
      });
    }, 2000);
  };

  const chunkingMenu = (
    <Menu onClick={({ key }) => handleChunkingMethodChange(key)}>
      <Menu.Item key="token-based" className="dropdown-menu-item">
        Token-based
      </Menu.Item>
      <Menu.Item key="character-based" className="dropdown-menu-item">
        Character-based
      </Menu.Item>
    </Menu>
  );

  useEffect(() => {
    const nodeCount = dbDag.filter((el) => el.node === "chunking").length;
    const id = `chunking_${nodeCount + 1}`;
    setNodeId(id);
    const val = {
      id: id,
      node: "chunking",
      properties: {
        type: "sentence",
        chunk_size: chunkingData.maxChunkSize,
        overlap: chunkingData.maxOverlap,
        separator: chunkingData.separator,
      },
    };
    setdbDag([...dbDag, val]);
  }, []);

  const updateProperties = (props) => {
    const node = dbDag.find((el) => el.id === nodeId);
    const p = node?.properties ? node.properties : {};
    const val = {
      id: nodeId,
      properties: { ...node, ...props },
    };
    setdbDag(dbDag.filter((el) => el !== node).concat(val));
  };

  return (
    <Card
      title={
        <div className="card-header">
          <LuBrainCircuit className="icon" />
          <span className="header-text">Chunking Data Configuration</span>
          <div onClick={handleRun} className="run-button">
            {loading ? (
              <FiLoader className="loader" spin />
            ) : (
              <FiPlay className="play" />
            )}
          </div>
        </div>
      }
      bordered={false}
      className="config-card"
    >
      <Tabs
        defaultActiveKey="1"
        className="config-tabs"
        onTabClick={(key) =>
          updateProperties({
            type:
              key === "1" ? "sentence" : key === "2" ? "paragraph" : "semantic",
          })
        }
      >
        {/* Sentence Chunking Tab */}
        <TabPane tab="Sentence" key="1">
          <div className="input-section">
            <div className="option-label">
              Max Chunk Size (characters/tokens)
            </div>
            <InputNumber
              value={chunkingData.maxChunkSize}
              onChange={(value) => {
                handleChunkingDataChange("maxChunkSize", value);
                updateProperties({ chunk_size: value });
              }}
              className="input-number"
            />
            <Text className="help-text">
              Max size per chunk, e.g., 512 tokens or characters.
            </Text>
          </div>

          <div className="input-section">
            <div className="option-label">Max Sentence Overlap</div>
            <InputNumber
              value={chunkingData.maxOverlap}
              onChange={(value) => {
                handleChunkingDataChange("maxOverlap", value);
                updateProperties({ overlap: value });
              }}
              className="input-number"
            />
            <Text className="help-text">
              Overlap in sentences between consecutive chunks.
            </Text>
          </div>

          <div className="input-section">
            <div className="option-label">Separator</div>
            <InputNumber
              value={chunkingData.separator}
              onChange={(value) => {
                handleChunkingDataChange("separator", value);
                updateProperties({ separator: value });
              }}
              className="input-number"
            />
            <Text className="help-text">
              Separator used for chunking sentences (e.g., '.', '?', '!').
            </Text>
          </div>

          <div className="input-section">
            <div className="option-label">Chunking Method</div>
            <Dropdown overlay={chunkingMenu} trigger={["click"]}>
              <div className="dropdown-button">
                <span>{chunkingMethod}</span>
                <FiChevronDown className="dropdown-icon" />
              </div>
            </Dropdown>
            <Text className="help-text">
              Select chunking method (token-based or character-based).
            </Text>
          </div>
        </TabPane>

        {/* Paragraph Chunking Tab */}
        <TabPane tab="Paragraph" key="2">
          <div className="input-section">
            <div className="option-label">
              Max Chunk Size (characters/tokens)
            </div>
            <InputNumber
              value={chunkingData.maxChunkSize}
              onChange={(value) => {
                handleChunkingDataChange("maxChunkSize", value);
                updateProperties({ chunk_size: value });
              }}
              className="input-number"
            />
            <Text className="help-text">
              Max size per chunk, e.g., 1000 characters.
            </Text>
          </div>

          <div className="input-section">
            <div className="option-label">Max Paragraph Overlap</div>
            <InputNumber
              onChange={(value) => {
                handleChunkingDataChange("maxOverlap", value);
                updateProperties({ overlap: value });
              }}
              className="input-number"
            />
            <Text className="help-text">
              Overlap in paragraphs between consecutive chunks.
            </Text>
          </div>

          <div className="input-section">
            <div className="option-label">Separator</div>
            <InputNumber
              value={chunkingData.separator}
              onChange={(value) => {
                handleChunkingDataChange("separator", value);
                updateProperties({ separator: value });
              }}
              className="input-number"
            />
            <Text className="help-text">
              Separator used for chunking paragraphs (e.g., '\n\n' or custom).
            </Text>
          </div>
        </TabPane>

        <TabPane tab="Semantic" key="3">
          <div className="input-section">
            <div className="option-label">
              Max Chunk Size (tokens/characters)
            </div>
            <InputNumber
              value={chunkingData.maxChunkSize}
              onChange={(value) => {
                handleChunkingDataChange("maxChunkSize", value);
                updateProperties({ chunk_size: value });
              }}
              className="input-number"
            />
            <Text className="help-text">
              Max size per chunk, e.g., 512 tokens or 2000 characters.
            </Text>
          </div>

          <div className="input-section">
            <div className="option-label">Chunk Overlap</div>
            <InputNumber
              value={chunkingData.maxOverlap}
              onChange={(value) => {
                handleChunkingDataChange("maxOverlap", value);
                updateProperties({ overlap: value });
              }}
              className="input-number"
            />
            <Text className="help-text">
              Overlap in tokens between consecutive chunks.
            </Text>
          </div>

          <div className="input-section">
            <div className="option-label">Similarity Threshold</div>
            <InputNumber
              value={chunkingData.similarityThreshold}
              onChange={(value) => {
                handleChunkingDataChange("similarityThreshold", value);
                updateProperties({ similarity: value });
              }}
              className="input-number"
            />
            <Text className="help-text">
              Threshold for semantic similarity when grouping sentences.
            </Text>
          </div>
        </TabPane>
      </Tabs>
    </Card>
  );
};

const ChunkingDataNode = () => {
  return (
    <div style={{ borderRadius: "5px", padding: "10px" }}>
      <Handle
        type="target"
        position={Position.Left}
        style={{
          background: "transparent",
          border: "2px solid #FFD700",
          borderRadius: "50%",
          width: "10px",
          height: "10px",
        }}
      />
      <Handle
        type="source"
        position={Position.Right}
        style={{
          background: "transparent",
          border: "2px solid #FFD700",
          borderRadius: "50%",
          width: "10px",
          height: "10px",
        }}
      />
      <ChunkingDataConfig />
    </div>
  );
};

export default ChunkingDataNode;
