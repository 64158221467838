// TestFlowConfig.js
import React, { useState } from "react";
import { Card, Input, Typography, Button, notification } from "antd";
import { GoDatabase } from "react-icons/go";
import { Handle, Position } from "react-flow-renderer";
import { FiLoader } from "react-icons/fi";
import { predictionApi } from "../services/apiHelpers";
import { useAtom } from "jotai";
import {
  selectedDatabaseAtom,
  uid,
  flowId,
  knowledgeRetrievalConfigAtom,
  bigModelConfigAtom,
} from "../state/state";
import Loader from "./Loader"; // Import the Loader component

const { Text } = Typography;

const TestFlowConfig = () => {
  const [userInput, setUserInput] = useState("");
  const [answer, setAnswer] = useState("");
  const [loading, setLoading] = useState(false);

  const [selectedDatabase] = useAtom(selectedDatabaseAtom);
  const [userId] = useAtom(uid);
  const [currentFlowId] = useAtom(flowId);
  const [knowledgeRetrievalConfig] = useAtom(knowledgeRetrievalConfigAtom);
  const [bigModelConfig] = useAtom(bigModelConfigAtom);

  const handleGenerateAnswer = async () => {
    if (!userInput) {
      notification.error({
        message: "No Input Provided",
        description: "Please enter a question before generating an answer.",
      });
      return;
    }

    if (currentFlowId === "") {
      notification.error({
        message: "Flow name must not be empty",
        description: "You must save a flow before entering a prompt.",
      });
      return;
    }

    if (!selectedDatabase) {
      notification.error({
        message: "No Database Selected",
        description: "Please select a database before generating an answer.",
      });
      return;
    }

    setLoading(true);

    // Construct the DAG with the configurations
    const dag = [];

    // Add Knowledge Retrieval node if configurations are set
    if (
      knowledgeRetrievalConfig &&
      Object.keys(knowledgeRetrievalConfig).length > 0
    ) {
      const {
        searchType,
        similarityScoreThreshold,
        topKStandard,
        topKMultiQuery,
        topKSmartCompression,
        topKLongDoc,
        distanceMetric,
        activeTabKey,
      } = knowledgeRetrievalConfig;

      let retrievalType = "standard";
      let kValue = topKStandard;

      if (activeTabKey === "1") {
        retrievalType = "standard";
        kValue = topKStandard;
      } else if (activeTabKey === "2") {
        retrievalType = "multi_query";
        kValue = topKMultiQuery;
      } else if (activeTabKey === "3") {
        retrievalType = "smart_compression";
        kValue = topKSmartCompression;
      } else if (activeTabKey === "4") {
        retrievalType = "long_doc";
        kValue = topKLongDoc;
      }

      const retrievalNode = {
        id_: "retrieval_01",
        node: "retrieval",
        properties: {
          ensemble: [
            {
              db_type: "vector_db",
              db_name: selectedDatabase.db_id,
              search_type: searchType.toLowerCase(), // 'similarity' or 'mmr'
              distance_metric: distanceMetric.toLowerCase(), // 'cosine', 'dot_product', 'euclidean'
              k: kValue,
              similarity_score_threshold: similarityScoreThreshold,
              retrieval_type: retrievalType,
              reranking: true,
            },
          ],
        },
      };

      dag.push(retrievalNode);
    }

    // Add LLM node with Big Model configurations
    const llmProperties = {
      model: {
        model_name: "gpt-40-mini",
        parameters: {
          temperature: 0.2,
          top_p: 0.3,
          max_tokens: 2048,
        },
      },
      prompt_template: userInput,
    };

    // Override default properties with Big Model configurations if available
    if (bigModelConfig && Object.keys(bigModelConfig).length > 0) {
      const {
        modelName,
        temperature,
        maxTokens,
        topP,
        frequencyPenalty,
        presencePenalty,
        stopSequences,
        promptTemplate,
      } = bigModelConfig;

      llmProperties.model.model_name = modelName;
      llmProperties.model.parameters.temperature = temperature;
      llmProperties.model.parameters.top_p = topP;
      llmProperties.model.parameters.max_tokens = maxTokens;
      llmProperties.model.parameters.frequency_penalty = frequencyPenalty;
      llmProperties.model.parameters.presence_penalty = presencePenalty;

      if (stopSequences) {
        llmProperties.model.parameters.stop = stopSequences
          .split(",")
          .map((s) => s.trim());
      }

      if (promptTemplate) {
        llmProperties.prompt_template = promptTemplate;
      }
    }

    const llmNode = {
      id_: "llm_01",
      node: "llm",
      input: "",
      properties: llmProperties,
    };

    dag.push(llmNode);

    // Add submit node
    const submitNode = {
      node: "submit",
      input: "",
      properties: {},
    };

    dag.push(submitNode);

    // Map userInput to the 'query' field in the request body
    const requestBody = {
      dag: dag,
      uid: userId,
      flow_id: currentFlowId,
      query: userInput,
    };

    try {
      const response = await predictionApi.post("/predict", requestBody);
      const answer = response.data || "No answer received";
      setAnswer(answer);
      setLoading(false);
      notification.success({
        message: "Answer Generated",
        description: "Your answer has been successfully generated.",
      });
    } catch (error) {
      setLoading(false);
      notification.error({
        message: "Error Generating Answer",
        description:
          error.response?.data?.message ||
          error.message ||
          "An error occurred while generating the answer.",
      });
    }
  };

  return (
    <Card
      title={
        <div className="card-header">
          <GoDatabase className="icon" />
          <span className="header-text">Test Flow</span>
          {loading && <FiLoader className="loader" spin />}
        </div>
      }
      bordered={false}
      className="upload-card"
    >
      <div style={{ display: "flex", flexDirection: "column", gap: "10px" }}>
        {/* Input Section */}
        <div className="input-section">
          <div className="option-label">Enter Your Question</div>
          <Input
            placeholder="Type your question here"
            value={userInput}
            onChange={(e) => setUserInput(e.target.value)}
            className="input-field"
          />
        </div>

        {/* Button to Generate Answer */}
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Button
            style={{ marginTop: "20px" }}
            type="primary"
            onClick={handleGenerateAnswer}
            loading={loading}
          >
            Generate Answer
          </Button>
        </div>

        {/* Output Section */}
        {answer && (
          <div className="output-section">
            <div className="option-label">Answer</div>
            <Text className="answer-text" style={{ color: "white" }}>
              {answer}
            </Text>
          </div>
        )}
      </div>
    </Card>
  );
};

const TestFlowNode = () => {
  return (
    <div style={{ backgroundColor: "#333", borderRadius: "5px" }}>
      {/* Input Handle */}
      <Handle
        type="target"
        position={Position.Left}
        style={{
          background: "transparent",
          border: "2px solid #FFD700",
          borderRadius: "50%",
          width: "10px",
          height: "10px",
        }}
      />
      {/* Output Handle */}
      <Handle
        type="source"
        position={Position.Right}
        style={{
          background: "transparent",
          border: "2px solid #FFD700",
          borderRadius: "50%",
          width: "10px",
          height: "10px",
        }}
      />
      <TestFlowConfig />
    </div>
  );
};

export default TestFlowNode;
