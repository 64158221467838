import React, { useCallback, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { styled, useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import CssBaseline from "@mui/material/CssBaseline";
import List from "@mui/material/List";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import SearchIcon from "@mui/icons-material/Search";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ChevronUpIcon from "@mui/icons-material/KeyboardArrowUp";
import ChevronDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import { InputBase } from "@mui/material";
import {
  applyNodeChanges,
  applyEdgeChanges,
  addEdge,
  MarkerType,
} from "react-flow-renderer";
import { CodeSandboxOutlined, ProfileOutlined } from "@ant-design/icons";

import ContentComponent from "./paper";
import CreationComponent from "./creation";
import stepsCategories from "./categories";
import Button from "./ui/button";

const drawerWidth = 280;

const Main = styled("main", { shouldForwardProp: (prop) => prop !== "open" })(
  ({ theme }) => ({
    flexGrow: 1,
    padding: theme.spacing(3),
    marginRight: 0,
  })
);

const AppBar = styled(MuiAppBar)(({ theme }) => ({
  transition: theme.transitions.create(["margin", "width"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  backgroundColor: "#252539",
}));

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
  justifyContent: "flex-start",
}));

const CustomDrawerContent = styled(Box)({
  height: "100%",
  overflow: "hidden",
  position: "relative",
});

export default function PersistentDrawerRight(isCanvas) {
  const navigate = useNavigate();
  const location = useLocation();
  const theme = useTheme();
  const [searchTerm, setSearchTerm] = useState("");
  const drawerContentRef = useRef(null);
  const [flow, setFlow] = useState("db-creation"); // State for toggle
  const [creationNodes, setCreationNodes] = useState([]);
  const [creationEdges, setCreationEdges] = useState([]);
  // State for the Generation view
  const [generationNodes, setGenerationNodes] = useState([]);
  const [generationEdges, setGenerationEdges] = useState([]);

  const isRoot = location.pathname === "/";

  const filteredCategories = stepsCategories
    .map((category) => {
      const filteredSteps = category.steps.filter((step) => step.flow === flow);
      const searchedSteps = filteredSteps.filter((step) =>
        step.name.toLowerCase().includes(searchTerm.toLowerCase())
      );

      return {
        ...category,
        steps: searchedSteps,
      };
    })
    .filter((category) => category.steps.length > 0);

  const customTheme = createTheme({
    typography: {
      fontFamily: "Roboto, Arial, sans-serif",
      h6: {
        fontSize: "0.875rem",
      },
      body2: {
        fontSize: "0.75rem",
      },
    },
  });
  console.log(flow);

  // Handle nodes and edges for Creation
  const handleCreationNodesChange = useCallback((changes) => {
    setCreationNodes((nds) => applyNodeChanges(changes, nds));
  }, []);

  const handleCreationEdgesChange = useCallback((changes) => {
    setCreationEdges((eds) => applyEdgeChanges(changes, eds));
  }, []);

  const handleCreationConnect = useCallback((connection) => {
    const newEdge = {
      id: `${connection.source}-${connection.target}`,
      source: connection.source || "",
      target: connection.target || "",
      animated: true,
      markerEnd: {
        type: MarkerType.Arrow,
      },
    };
    setCreationEdges((eds) => addEdge(newEdge, eds));
  }, []);

  // Handle nodes and edges for Generation
  const handleGenerationNodesChange = useCallback((changes) => {
    setGenerationNodes((nds) => applyNodeChanges(changes, nds));
  }, []);

  const handleGenerationEdgesChange = useCallback((changes) => {
    setGenerationEdges((eds) => applyEdgeChanges(changes, eds));
  }, []);

  const handleGenerationConnect = useCallback((connection) => {
    const newEdge = {
      id: `${connection.source}-${connection.target}`,
      source: connection.source || "",
      target: connection.target || "",
      animated: true,
      markerEnd: {
        type: MarkerType.Arrow,
      },
    };
    setGenerationEdges((eds) => addEdge(newEdge, eds));
  }, []);

  // const filteredCategories = stepsCategories
  //   .map((category) => ({
  //     ...category,
  //     steps: category.steps.filter((step) =>
  //       step.name.toLowerCase().includes(searchTerm.toLowerCase())
  //     ),
  //   }))
  //   .filter(
  //     (category) =>
  //       category.steps.length > 0 ||
  //       category.category.toLowerCase().includes(searchTerm.toLowerCase())
  //   );

  const scrollUp = () => {
    if (drawerContentRef.current) {
      drawerContentRef.current.scrollBy({
        top: -100,
        behavior: "smooth",
      });
    }
  };

  const scrollDown = () => {
    if (drawerContentRef.current) {
      drawerContentRef.current.scrollBy({
        top: 100,
        behavior: "smooth",
      });
    }
  };

  // Define onDragStart function
  const onDragStart = (stepId, event) => {
    event.dataTransfer.setData("application/reactflow", stepId);
    event.dataTransfer.effectAllowed = "move";
  };

  const handleDrop = (event) => {
    event.preventDefault();
    const stepId = event.dataTransfer.getData("application/reactflow");
    console.log("Dropped step ID:", stepId); // Handle the step drop here
    // Pass stepId to ContentComponent to handle its logic
  };

  const handleDragOver = (event) => {
    event.preventDefault(); // Necessary for allowing a drop
  };

  const handleFlowChange = (event, newFlow) => {
    if (newFlow !== null) {
      setFlow(newFlow);
    }
  };

  return (
    <ThemeProvider theme={customTheme}>
      <Box sx={{ display: "flex" }}>
        <CssBaseline />

        {/* AppBar */}
        <AppBar position="fixed">
          <Toolbar>
            <img
              src="/Images/ia-logo-icon.png"
              alt="ia"
              width="40"
              height="40"
              onClick={() => navigate("/")}
              className="cursor-pointer mr-2"
            />
            <Typography
              variant="h6"
              noWrap
              sx={{ flexGrow: 1 }}
              component="div"
            >
              Inference Analytics AI
            </Typography>
            <Box sx={{ display: "flex" }}>
              <div className="mx-4">
                {isRoot ? (
                  <Button
                    icon={<ProfileOutlined />}
                    type="default"
                    placeholder="SDK"
                    onClick={() => window.open("/documentation", "_blank")}
                  />
                ) : (
                  <Button
                    icon={<CodeSandboxOutlined />}
                    type="default"
                    placeholder="Studio"
                    onClick={() => navigate("/")}
                  />
                )}
              </div>
            </Box>
            <Box sx={{ display: "flex", marginRight: "275px" }}>
              <ToggleButtonGroup
                value={flow}
                exclusive
                onChange={handleFlowChange}
                aria-label="flow mode"
              >
                <ToggleButton
                  value="db-creation"
                  aria-label="creation flow"
                  sx={{
                    color: flow === "db-creation" ? "#000" : "white", // Change text color based on state
                    backgroundColor:
                      flow === "db-creation" ? "#fff" : "transparent", // Change background if selected
                    "&.Mui-selected": {
                      color: "#000", // Text color when selected
                      backgroundColor: "#fff", // Background color when selected
                    },
                  }}
                >
                  Creation Flow
                </ToggleButton>
                <ToggleButton
                  value="generation"
                  aria-label="generation flow"
                  sx={{
                    color: flow === "generation" ? "#000" : "white", // Change text color based on state
                    backgroundColor:
                      flow === "generation" ? "#fff" : "transparent", // Change background if selected
                    "&.Mui-selected": {
                      color: "#000", // Text color when selected
                      backgroundColor: "#fff", // Background color when selected
                    },
                  }}
                >
                  Generation Flow
                </ToggleButton>
              </ToggleButtonGroup>
            </Box>
          </Toolbar>
        </AppBar>

        {/* Main Content */}
        <Main onDrop={handleDrop} onDragOver={handleDragOver}>
          <DrawerHeader />
          {flow === "db-creation" ? (
            <CreationComponent
              nodes={creationNodes}
              setNodes={setCreationNodes}
              edges={creationEdges}
              setEdges={setCreationEdges}
              onNodesChange={handleCreationNodesChange}
              onEdgesChange={handleCreationEdgesChange}
              onConnect={handleCreationConnect}
            />
          ) : (
            <ContentComponent
              nodes={generationNodes}
              setNodes={setGenerationNodes}
              edges={generationEdges}
              setEdges={setCreationEdges}
              onNodesChange={handleGenerationNodesChange}
              onEdgesChange={handleGenerationEdgesChange}
              onConnect={handleGenerationConnect}
            />
          )}
        </Main>

        {/* Persistent Drawer */}
        <Drawer
          sx={{
            width: drawerWidth,
            flexShrink: 0,
            "& .MuiDrawer-paper": {
              width: drawerWidth,
              backgroundColor: "#1E1E2D",
            },
          }}
          variant="permanent"
          anchor="right"
          open
        >
          <DrawerHeader>{/* Remove Close Button */}</DrawerHeader>

          {/* Search Bar */}
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              padding: theme.spacing(1, 2),
            }}
          >
            <SearchIcon sx={{ color: "#fff" }} />
            <InputBase
              placeholder="Search…"
              inputProps={{ "aria-label": "search" }}
              sx={{ marginLeft: theme.spacing(1), flex: 1, color: "#fff" }}
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
          </Box>

          {/* Scroll Controls */}
          <IconButton
            onClick={() =>
              drawerContentRef.current.scrollBy({
                top: -100,
                behavior: "smooth",
              })
            }
            sx={{ color: "#fff", backgroundColor: "#1E1E2D" }}
          >
            <ChevronUpIcon />
          </IconButton>

          <CustomDrawerContent ref={drawerContentRef}>
            <List>
              {filteredCategories.map((category) => (
                <Accordion key={category.category} defaultExpanded>
                  <AccordionSummary
                    sx={{
                      backgroundColor: "#1E1E2D",
                      padding: "4px 10px",
                    }}
                    expandIcon={<ChevronRightIcon sx={{ color: "#fff" }} />}
                    aria-controls={`panel-${category.category}-content`}
                    id={`panel-${category.category}-header`}
                  >
                    <Typography
                      sx={{ color: "#fff", fontSize: "0.875rem" }}
                      variant="h6"
                    >
                      {category.category}
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails
                    sx={{
                      backgroundColor: "#1E1E2D",
                      padding: "4px 10px",
                    }}
                  >
                    <List disablePadding>
                      {category.steps.map((step) => (
                        <ListItem
                          key={step.id}
                          disablePadding
                          draggable
                          onDragStart={(e) => onDragStart(step.id, e)}
                          sx={{ margin: "2px 0" }}
                        >
                          <ListItemButton
                            sx={{
                              padding: "4px 10px",
                            }}
                          >
                            <ListItemIcon
                              sx={{ color: "#fff", minWidth: "24px" }}
                            >
                              {step.icon}
                            </ListItemIcon>
                            <ListItemText
                              primary={step.name}
                              sx={{ color: "#fff", fontSize: "0.75rem" }}
                            />
                          </ListItemButton>
                        </ListItem>
                      ))}
                    </List>
                  </AccordionDetails>
                </Accordion>
              ))}
            </List>
          </CustomDrawerContent>

          {/* Scroll Down Control */}
          <IconButton
            onClick={() =>
              drawerContentRef.current.scrollBy({
                top: 100,
                behavior: "smooth",
              })
            }
            sx={{ color: "#fff", backgroundColor: "#1E1E2D" }}
          >
            <ChevronDownIcon />
          </IconButton>
        </Drawer>
      </Box>
    </ThemeProvider>
  );
}
